import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import {
    SturmHorizontalNavigationBasicItemComponent,
} from '@sturm/components/navigation/horizontal/components/basic/basic.component';
import {
    SturmHorizontalNavigationBranchItemComponent,
} from '@sturm/components/navigation/horizontal/components/branch/branch.component';
import {
    SturmHorizontalNavigationDividerItemComponent,
} from '@sturm/components/navigation/horizontal/components/divider/divider.component';
import {
    SturmHorizontalNavigationSpacerItemComponent,
} from '@sturm/components/navigation/horizontal/components/spacer/spacer.component';
import { SturmHorizontalNavigationComponent } from '@sturm/components/navigation/horizontal/horizontal.component';
import {
    SturmVerticalNavigationAsideItemComponent,
} from '@sturm/components/navigation/vertical/components/aside/aside.component';
import {
    SturmVerticalNavigationBasicItemComponent,
} from '@sturm/components/navigation/vertical/components/basic/basic.component';
import {
    SturmVerticalNavigationCollapsableItemComponent,
} from '@sturm/components/navigation/vertical/components/collapsable/collapsable.component';
import {
    SturmVerticalNavigationDividerItemComponent,
} from '@sturm/components/navigation/vertical/components/divider/divider.component';
import {
    SturmVerticalNavigationGroupItemComponent,
} from '@sturm/components/navigation/vertical/components/group/group.component';
import {
    SturmVerticalNavigationSpacerItemComponent,
} from '@sturm/components/navigation/vertical/components/spacer/spacer.component';
import { SturmVerticalNavigationComponent } from '@sturm/components/navigation/vertical/vertical.component';
import { SturmScrollbarModule } from '@sturm/directives/scrollbar/public-api';


@NgModule({
    declarations: [
        SturmHorizontalNavigationBasicItemComponent,
        SturmHorizontalNavigationBranchItemComponent,
        SturmHorizontalNavigationDividerItemComponent,
        SturmHorizontalNavigationSpacerItemComponent,
        SturmHorizontalNavigationComponent,
        SturmVerticalNavigationAsideItemComponent,
        SturmVerticalNavigationBasicItemComponent,
        SturmVerticalNavigationCollapsableItemComponent,
        SturmVerticalNavigationDividerItemComponent,
        SturmVerticalNavigationGroupItemComponent,
        SturmVerticalNavigationSpacerItemComponent,
        SturmVerticalNavigationComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        RouterModule,
        SturmScrollbarModule,
        TranslocoModule,
    ],
    exports     : [
        SturmHorizontalNavigationComponent,
        SturmVerticalNavigationComponent,
    ],
})
export class SturmNavigationModule
{
}
