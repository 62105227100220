import { Injectable } from '@angular/core';
import { TemplateString } from '@awesome-nodes/object';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import moment from 'moment-business-days';
import { Nullable } from 'simplytyped';
import { OrderItem } from './order-item.model';


@Injectable({ providedIn: 'root' })
export class OrderItemService
{
    readonly items = new Array<OrderItem>();

    readonly sessionStoreKeyTemplate = TemplateString`${'sessionStoreKey'}=${'sessionStorePrefix'}orderItems`;

    constructor(
        private readonly _configService: ConfigService,
    )
    {
    }

    private get orderItemsSessionStoreKey(): string
    {
        return this.sessionStoreKeyTemplate({
            sessionStoreKey   : this._configService.getValue('sessionStoreKey'),
            sessionStorePrefix: this._configService.getValue('sessionStorePrefix'),
        });
    }

    load(): void
    {
        if (this.items.length === 0) {
            this.restoreSessionOrderItems();
        }
    }

    loadByKey(orderItemId: string): Nullable<OrderItem>
    {
        return this.items.find(item => item.id === orderItemId);
    }

    add(orderItem: OrderItem): void
    {
        this.items.unshift(orderItem);
        this.saveSessionOrderItems();
    }

    update(orderItem: OrderItem): void
    {
        this.saveSessionOrderItems();
    }

    delete(orderItem: OrderItem): void
    {
        this.items.splice(this.items.indexOf(orderItem), 1);
        this.saveSessionOrderItems();
    }

    clear(): void
    {
        this.items.length = 0;
        this.saveSessionOrderItems();
    }

    private restoreSessionOrderItems(): void
    {
        const items = localStorage.getItem(this.orderItemsSessionStoreKey);
        if (items) {
            this.items.push(...JSON.parse(items)
                .filter((_orderItem: OrderItem) =>
                {
                    const cartAddDay = moment(_orderItem.cartDate).subtract(1, 'days').get('dayOfYear');
                    const dayOfYear  = moment().subtract(1, 'days').get('dayOfYear');
                    // add unique items which are not older than one day.
                    return cartAddDay === dayOfYear
                        && !this.items.some(item => item.id === _orderItem.id);
                }));
            this.saveSessionOrderItems();
        }
    }

    private saveSessionOrderItems(): void
    {
        localStorage.setItem(
            this.orderItemsSessionStoreKey,
            JSON.stringify(this.items),
        );
    }
}
