import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { SturmHorizontalNavigationComponent, SturmNavigationService } from '@sturm/components/navigation';
import {
    CartService,
    MaterialCartDatasource,
    MaterialPrice,
    Order,
    OrderItem,
    OrderItemDatasource,
    OrderItemService,
    OrderItemTableEntry,
} from '@sturm/lib/admin';
import {
    Action,
    EntryAction,
    IQueryTableEntryDetailComponent,
    MinMaxDatepickerComponent,
    QueryTableComponent,
} from '@sturm/lib/components';
import { DateFormatPipe } from '@sturm/lib/shared';
import { SturmMediaWatcherService } from '@sturm/services/media-watcher';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { TableFilter } from '@yukawa/chain-base-angular-domain';
import { MaterialCartQueryStore } from 'app/modules/admin/material/comp/material-cart/material-cart-query.store';
import { MaterialQuantityComponent } from 'app/modules/admin/material/comp/material-cart/quantity/quantity.component';
import {
    SubmitMaterialOrderComponent,
    SubmitOrderDialogData,
} from 'app/modules/admin/material/comp/material-cart/submit-order/submit-order.component';
import { MaterialTotalComponent } from 'app/modules/admin/material/comp/material-cart/total/total.component';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { ConstructorFor } from 'simplytyped';


@Component({
    selector   : 'app-material-cart',
    templateUrl: './material-cart.component.html',
    styleUrls  : ['./material-cart.component.scss'],
})
export class MaterialCartComponent implements OnInit, OnDestroy
{
    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
    @ViewChild(MinMaxDatepickerComponent) minMaxDatepicker: MinMaxDatepickerComponent;
    @ViewChild(QueryTableComponent) orderItemsTable: QueryTableComponent;
    @ViewChild('filter', { static: true }) filter: ElementRef;

    drawerMode: 'side' | 'over';

    readonly dataStore                   = MaterialCartQueryStore;
    readonly reloadEntry                 = new EventEmitter<OrderItemTableEntry>();
    readonly materialFilter: TableFilter = {};

    materialActions: Array<Action> = [
        {
            name: 'MATERIAL.REMOVE',
            type: 'button',
        },
    ];

    cellComponents = new Map<keyof OrderItem, ConstructorFor<IQueryTableEntryDetailComponent>>([
        ['quantity', MaterialQuantityComponent],
        ['total', MaterialTotalComponent],
    ]);

    order: Order = {} as Order;
    desiredDeliveryDate!: Date;

    // Private
    readonly #unsubscribeAll: Subject<any> = new Subject<any>();
    private _dateFormatPipe: DateFormatPipe;

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _cartService: CartService,
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _dialog: MatDialog,
        private readonly _matSnackBar: MatSnackBar,
        private readonly _materialCartDatasource: MaterialCartDatasource,
        private readonly _orderItemDatasource: OrderItemDatasource,
        private readonly _router: Router,
        private readonly _sturmMediaWatcherService: SturmMediaWatcherService,
        private readonly _translocoService: TranslocoService,
    )
    {
    }

    get subtotalPrice(): string
    {
        return MaterialPrice.sumTotal(
            'valueTotal',
            ...this._orderItemDatasource.entries.map((entry: OrderItemTableEntry) => entry.price),
        );
    }

    get totalDiscountPrice(): string
    {
        return MaterialPrice.discountTotal(
            'discountTotal',
            ...this._orderItemDatasource.entries.map((entry: OrderItemTableEntry) => entry.price),
        );
    }

    get totalPrice(): string
    {
        return MaterialPrice.sumTotal(
            'total',
            ...this._orderItemDatasource.entries.map((entry: OrderItemTableEntry) => entry.price),
        );
    }

    static updateCartBadge(): void
    {
        const sturmNavigationService = AppInjector.get(SturmNavigationService);
        const orderItemService       = AppInjector.get(OrderItemService);
        const navigationElement      = sturmNavigationService.getNavigation('cart')[0];

        if (!navigationElement) {
            return;
        }
        navigationElement.badge.title = '0';

        const refreshMainNavigation = (navigations = [
            'mainNavigation',
            'mobileNavigation',
        ]): void =>
        {
            navigations.forEach(navigation => (sturmNavigationService.getComponent(navigation) as SturmHorizontalNavigationComponent)
                ?.refresh());
        };
        navigationElement.icon      = orderItemService.items.length > 0
            ? 'sturm:cart-filled'
            : 'sturm:cart';

        refreshMainNavigation();

        setTimeout(() =>
        {
            navigationElement.badge.title = orderItemService.items.length.toString();
            refreshMainNavigation();
        }, 100);
    }

    async ngOnInit(): Promise<void>
    {
        this._dateFormatPipe = new DateFormatPipe(this._translocoService);

        // Subscribe to media query change
        this._sturmMediaWatcherService.onMediaQueryChange$('(min-width: 1440px)')
            .pipe(takeUntil(this.#unsubscribeAll))
            .subscribe((state) =>
            {
                // Calculate the drawer mode
                this.drawerMode = state.matches ? 'side' : 'over';
            });

        this.matDrawer._closedStream.subscribe(() =>
        {
            if (!this._router.getCurrentNavigation()) {
                this.orderItemsTable.dataSource.selectedEntry = null;
            }
        });

        this._cartService.load();
        this.order = this._cartService.order;
        this._changeDetector.detectChanges();
        if (this.order.deliveryDate) {
            if (moment(this.order.deliveryDate).dayOfYear() >= moment(this.minMaxDatepicker.minDate).dayOfYear()) {
                this.desiredDeliveryDate = new Date(this.order.deliveryDate);
            }
            else {
                delete this.order.deliveryDate;
                this._cartService.save();
            }
        }
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.#unsubscribeAll.next(null);
        this.#unsubscribeAll.complete();
    }

    onBackdropClicked(): void
    {
        // Go back to the list
        this._router.navigate(['.'], {
            relativeTo         : this._activatedRoute,
            queryParamsHandling: 'preserve',
        });
    }

    reload(): void
    {
        MaterialCartComponent.updateCartBadge();
        this.orderItemsTable.reload();
    }

    async entryAction($event: EntryAction<OrderItemTableEntry>): Promise<void>
    {
        await this._orderItemDatasource.delete($event.row);
        this.reload();
    }

    orderChanged(): void
    {
        this.order.deliveryDate = this.desiredDeliveryDate?.toISOString();
        this._cartService.save();
    }

    async submitOrder(): Promise<void>
    {
        const matDialogRef = this._dialog.open(SubmitMaterialOrderComponent, {
            autoFocus: 'first-heading',
            minWidth : '44.625rem',
            minHeight: '22.5625rem',
            width    : '44.625rem',
            height   : '22.5625rem',
            data     : {
                order     : this.order,
                orderItems: this._orderItemDatasource.entries.map((entry: OrderItemTableEntry) =>
                    {
                        entry.entity.price = new MaterialPrice(entry.price, entry.price.discount).total;
                        return entry.entity;
                    },
                ),
            } as SubmitOrderDialogData,
        });

        matDialogRef.afterClosed().subscribe(() =>
        {
            this._materialCartDatasource.entries.length = 0;
            this.reload();
        });
    }
}
