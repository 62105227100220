import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import moment, { MomentInput } from 'moment';
import { concatMapTo, of, repeat, Subscription, timer } from 'rxjs';
import { ElapsedDatePipe } from '../pipe/elapsed-date.pipe';


@Directive({
    selector: '[elapsedDate]',
})
export class ElapsedDateDirective implements OnInit, AfterViewInit, OnDestroy
{
    #intervalSubscription!: Subscription;

    time!: number;

    @Input()
    value: MomentInput;

    @Input()
    update: boolean = true;

    @Input()
    updateInterval: number = 5;

    private readonly _elapsedPipe: ElapsedDatePipe;

    public constructor(
        private readonly _elementRef: ElementRef<HTMLElement>,
        _translocoService: TranslocoService,
    )
    {
        this._elapsedPipe = new ElapsedDatePipe(_translocoService);
    }

    public ngOnInit(): void
    {
        const innerText = this._elapsedPipe.transform(this.value) || String(this.value);
        if (this._elementRef.nativeElement.innerText !== innerText) {
            this._elementRef.nativeElement.innerText = innerText;
        }
    }

    public ngAfterViewInit(): void
    {
        if (this.update) {
            this.#intervalSubscription = of(null)
                .pipe(
                    concatMapTo(timer(1000)),
                    repeat(),
                )
                .subscribe(() =>
                {
                    const diffSeconds = moment().diff(this.value, 'seconds');
                    if (diffSeconds % this.updateInterval === 0) {
                        this.ngOnInit();
                    }
                });
        }
    }

    public ngOnDestroy(): void
    {
        this.#intervalSubscription?.unsubscribe();
    }
}
