import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TranslocoService } from '@ngneat/transloco';
import { MOMENT_FORMATS } from '@sturm/lib/shared';
import { Moment } from 'moment';
import moment from 'moment-business-days';
import { Nullable } from 'simplytyped';


@Component({
    selector   : 'lib-min-max-datepicker',
    templateUrl: './min-max-datepicker.component.html',
    styleUrls  : ['./min-max-datepicker.component.scss'],
    providers  : [
        {
            provide: DateAdapter, useClass: MomentDateAdapter,
            deps   : [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {
            provide: MAT_DATE_FORMATS, useValue: MOMENT_FORMATS,
        },
    ],
})
export class MinMaxDatepickerComponent implements OnInit, OnChanges
{
    @Input()
    date: Nullable<Date>;

    @Input()
    minDays: Nullable<number> = null;

    @Input()
    maxDays: Nullable<number> = null;

    @Input()
    disabled: boolean = false;

    @Input()
    label!: string;

    @Input()
    public hint!: string;

    @Output()
    readonly dateChange              = new EventEmitter<Nullable<Date>>();
    private _minDate: Nullable<Date> = null;
    private _maxDate: Nullable<Date> = null;

    constructor(
        private readonly _dateAdapter: DateAdapter<Date>,
        private readonly _translocoService: TranslocoService,
    )
    {
    }

    get minDate(): Nullable<Date>
    {
        return this._minDate;
    }

    @Input()
    set minDate(value: Nullable<Date>)
    {
        this._minDate = value;
    }

    get maxDate(): Nullable<Date>
    {
        return this._maxDate;
    }

    @Input()
    set maxDate(value: Nullable<Date>)
    {
        this._maxDate = value;
    }

    ngOnInit(): void
    {
        this._translocoService.events$.subscribe((event) =>
        {
            if (event.type === 'langChanged') {
                this._dateAdapter.setLocale(event.payload.langName);
            }
        });

        this._dateAdapter.setLocale(this._translocoService.getActiveLang());

        if (this.minDays !== null) {
            this._minDate = moment().businessAdd(this.minDays as number).toDate();
        }
        if (this.maxDays !== null) {
            this._maxDate = moment().add({ day: this.maxDays }).toDate();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void
    {
        const getDate = (days: number): Date => days >= 0
            ? moment().businessAdd(days).toDate()
            : moment().businessSubtract(days * -1).toDate();

        if (changes['minDays']) {
            this._minDate = getDate(changes['minDays'].currentValue);
        }
        if (changes['maxDays']) {
            this._maxDate = getDate(changes['maxDays'].currentValue);
        }
    }

    public dateChanged($event: MatDatepickerInputEvent<Date | Moment>): void
    {
        this.dateChange.emit($event.value instanceof Date ? $event.value : $event.value?.toDate());
    }
}
