<!-- Loading bar -->
<sturm-loading-bar></sturm-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <sturm-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container sturmVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex flex-col items-center h-40 pt-6 px-8">
                <mat-icon [svgIcon]="'sturm:logo'" class="text-white h-20 w-auto"></mat-icon>
                <span class="mt-2 uppercase leading-6 text-xl tracking-widest" transloco="SITE.TITLE"></span>
            </div>
        </ng-container>
    </sturm-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative items-start w-full h-26 mt-8 px-4 md:px-6 z-49 border-b-2.5 border-b-primary bg-card dark:bg-sturm-darkerBlue print:hidden overflow-visible md:overflow-hidden"
        [ngClass]="{
            'flex flex-0': isScreenSmall,
            'grid grid-cols-3': !isScreenSmall
        }">

        <!-- Components -->
        <div class="flex items-center pl-2 space-x-0.5 sm:space-x-2">
            <languages></languages>
            <sturm-fullscreen class="hidden md:block"></sturm-fullscreen>
            <!-- <shortcuts></shortcuts> -->

            <user></user>
        </div>

        <!-- Logo -->
        <div class="flex flex-col items-center m-auto mt-0">
            <div class="hidden md:flex">
                <!-- Light version -->
                <mat-icon [svgIcon]="'sturm:logo'" class="dark:hidden w-56 h-auto text-primary"></mat-icon>
                <!-- Dark version -->
                <mat-icon [svgIcon]="'sturm:logo'" class="hidden dark:flex w-56 h-auto text-primary"></mat-icon>
            </div>
            <mat-icon [svgIcon]="'sturm:icon'" class="flex md:hidden icon-size-12 w-8 h-auto"></mat-icon>
            <!--<span class="uppercase leading-6 text-xl" [transloco]="pageName"></span>-->
            <span class="mt-2 uppercase leading-6 text-xl tracking-widest" transloco="SITE.TITLE"></span>
        </div>

        <ng-container *ngIf="!isScreenSmall">
            <!-- Horizontal navigation -->
            <sturm-horizontal-navigation
                class="ml-auto mr-2"
                [name]="'mainNavigation'"
                [navigation]="navigation.horizontal"></sturm-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>

            <!-- Horizontal navigation -->
            <sturm-horizontal-navigation
                class="-mt-1 mr-2"
                [name]="'mobileNavigation'"
                [navigation]="mobileNavigation"></sturm-horizontal-navigation>

        </ng-container>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden overflow-hidden">
        <span class="font-medium text-sturm-footer">Sturm &copy; {{currentYear}}</span>
        <mat-icon svgIcon="sturm:logo" class="text-sturm-footer w-22 ml-auto mr-auto"></mat-icon>
        <span class="font-medium text-sturm-footer">v {{ version }}</span>
        <ng-container [ngTemplateOutlet]="logoBackground" [ngTemplateOutletContext]="{ y: '-72%'}"></ng-container>
    </div>

</div>

<ng-template #logoBackground let-className='className' let-y="y">
    <!--<img class="absolute left-0 top-0 right-0 bottom-0 -z-10 object-cover {{ className }}" alt=""
         [ngStyle]="{ transform: 'translate(0,' + y + ')'}"
         src="assets/images/Header.jpeg"/>-->
</ng-template>
