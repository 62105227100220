<mat-table mat-table class="w-full"
           [dataSource]="dataSource"
           matSort matSortDisableClear (matSortChange)="onSortChanged($event)"
           [matSortActive]="defaultSort?.active || ''" [matSortDirection]="defaultSort?.direction || ''">

    <ng-container matColumnDef="expand">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
            <button mat-icon-button>
                <mat-icon>{{ dataSource.selectedEntry === row ? 'expand_less' : 'expand_more'}}</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <ng-container *ngFor="let _detail of dataSource.headers" matColumnDef="{{ _detail.key }}">

        <mat-header-cell mat-sort-header="{{ _detail.key }}" [disabled]="!_detail.sortable" *matHeaderCellDef>
            {{ _detail.label | transloco }}
        </mat-header-cell>

        <mat-cell *matCellDef="let row">
            <ng-container [ngSwitch]="_detail.group !== undefined && _detail.tableGroup === true">

                <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="itemTemplate"
                              [ngTemplateOutletContext]="{ row, detail: _detail }">
                </ng-container>

                <ng-container *ngSwitchCase="true">

                    <div class="flex space-x-2">
                        <ng-container *ngFor="let _group of dataSource.detailGroups.get(_detail.key)">

                            <div class="flex" [ngClass]="{
                            'flex-col space-y-1': _group.direction === 'vertical',
                            'flex-row space-x-1': _group.direction === 'horizontal'
                        }">
                                <ng-container *ngFor="let _groupDetail of _group.details"
                                              [ngTemplateOutlet]="itemTemplate"
                                              [ngTemplateOutletContext]="{ row, detail: _groupDetail }"></ng-container>
                            </div>

                        </ng-container>

                    </div>

                </ng-container>

            </ng-container>

        </mat-cell>

    </ng-container>

    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef [transloco]="entryActionsLabel"></mat-header-cell>
        <mat-cell *matCellDef="let row" class="space-x-2 text-sm font-medium">
            <ng-container *ngFor="let _action of actions" [ngSwitch]="_action.type">
                <ng-container *ngIf="!_action.available || _action.available(row)">
                    <button *ngSwitchCase="'icon'" mat-icon-button (click)="onEntryAction($event, row, _action)"
                            [matTooltip]="_action.name | transloco">
                        <mat-icon svgIcon="{{ _action.icon }}"></mat-icon>
                    </button>
                    <button *ngSwitchCase="'button'" mat-flat-button (click)="onEntryAction($event, row, _action)">
                        <mat-icon class="mr-2" *ngIf="_action.icon" svgIcon="{{ _action.icon }}"></mat-icon>
                        <span transloco="{{ _action.name }}"></span>
                    </button>
                    <a *ngSwitchCase="'link'" (click)="onEntryAction($event, row, _action)"
                       class="hover:text-black" [ngClass]="{
                        'text-primary-500': !row.selected,
                        'text-white': row.selected
                     }">
                        <span transloco="{{ _action.name }}"></span>
                    </a>
                </ng-container>
            </ng-container>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="dataSource.displayedColumns;sticky: true"></mat-header-row>
    <mat-row #matRow matRipple [matRippleDisabled]="!rowSelect"
             *matRowDef="let row; columns:dataSource.displayedColumns;"
             [class]="row.rowClass"
             [ngClass]="{
                'mat-row-active': row.selected
             }"
             [ngStyle]="{
                cursor: rowSelect ? 'pointer' : 'default'
             }"
             (click)="rowClicked(row)">
    </mat-row>

</mat-table>

<mat-paginator class="paginator"
               [ngClass]="{
        'hidden': !displayPager
    }"
               [length]="100"
               [pageSizeOptions]="[5, 10, 25, 100]"
               [showFirstLastButtons]="true"
               aria-label="Select page">
</mat-paginator>

<ng-template #itemTemplate let-row="row" let-detail="detail">

    <ng-container *ngIf="row.details.get(detail.key)?.showInTable"
                  [ngSwitch]="cellComponents?.has(detail.key)">
        <ng-container *ngSwitchCase="true" libCellComponent
                      [entry]="row"
                      [detail]="detail"
                      [component]="getCellComponent(detail.key)"></ng-container>

        <ng-container *ngSwitchDefault [ngSwitch]="detail.type">

            <span *ngSwitchCase="'date'" @fadeIn
                  class="whitespace-nowrap {{ detail.class }}">{{ getRowDetail(row, detail) | dateFormat: 'L' }}</span>

            <ng-container *ngSwitchCase="'image'" [ngSwitch]="!!getRowDetail(row, detail)">

                <div class="inline-block w-20 h-auto flex justify-center items-center">
                    <mat-icon *ngSwitchCase="false" class="icon-size-14 text-hint"
                              [svgIcon]="'iconsmind:file'" @fadeIn></mat-icon>

                    <img *ngSwitchCase="true" class="h-auto w-auto object-cover {{ detail.class }}" [ngClass]="{
                        'rounded-lg': detail.class?.indexOf('rounded') === -1
                    }"
                         [src]="getRowDetail(row, detail)" alt="Thumbnail" @fadeIn>

                </div>

            </ng-container>

            <ng-container *ngSwitchCase="'boolean'">
                <mat-checkbox *ngIf="row.details.get(detail.key)" [checked]="getRowDetail(row, detail) !== ''"
                              disabled></mat-checkbox>
            </ng-container>

            <ng-container *ngSwitchDefault [ngSwitch]="detail.markdownSupport === true">
                <span *ngSwitchCase="false" @fadeIn class="{{ detail.class }}">{{ getRowDetail(row, detail) }}</span>
                <span *ngSwitchCase="true" @fadeIn [innerHTML]="getRowDetail(row, detail)"
                      class="{{ detail.class }}"></span>
            </ng-container>

        </ng-container>

    </ng-container>

</ng-template>
