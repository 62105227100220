import { Injectable } from '@angular/core';
import { QueryTableDatasource, QueryTableDatasourceException } from '@sturm/lib/components';
import { OrderFilter } from '@sturm/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { lastValueFrom } from 'rxjs';
import { OrderTableEntry } from './order-table-entry.model';
import { Order } from './order.model';
import { OrderService } from './order.service';


@Injectable({
    providedIn: 'root',
})
export class OrderDatasource extends QueryTableDatasource<Order, OrderFilter>
{
    constructor()
    {
        super();
    }

    public async read(filter?: OrderFilter): Promise<Array<Order>>
    {
        this.query = await lastValueFrom(AppInjector.get(OrderService).queryOrder({
            ...filter,
        }));
        return this.query.items;
    }

    public newEntry(item?: Order): OrderTableEntry
    {
        const entry = new OrderTableEntry(item);
        entry.init();
        return entry;
    }

    public async create(entry: OrderTableEntry): Promise<OrderTableEntry>
    {
        entry = this.newEntry(await lastValueFrom(AppInjector.get(OrderService).create(entry.entity)));
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: OrderTableEntry, order?: Partial<Order>): Promise<OrderTableEntry>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Order file ${entry.id} does not exist.`);
        }

        if (order) {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(OrderService).update({
                ...order,
                orderId: entry.entity.orderId,
            } as Order)) as Order);
        }

        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: OrderTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Order file ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(OrderService).delete(entry.entity));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(orderId: number): Promise<OrderTableEntry>
    {
        return this.newEntry((await lastValueFrom(AppInjector.get(OrderService).loadByKey(orderId))));
    }
}
