import { Change, Owner, Person } from '@yukawa/chain-base-angular-domain';
import { User as IUser } from '@yukawa/chain-main-angular-core';
import { Role as YukawaRole } from '@yukawa/chain-main-angular-session';
import { Account, GroupContext } from '@yukawa/chain-security-angular-core';


export interface User extends IUser
{
    account: Account;
    change: Required<Change>;
    created: Required<Change>;
    defaultOrgId: string;
    details: Record<string, object>;
    groupContexts: Required<GroupContext>[];
    owner: Required<Owner>;
    person: Required<Person>;
    username: string;
}

export class Role extends YukawaRole
{
    static readonly shop = Role.enum<Role>(0x2000, 'ROLE_SHOP_UI');
}
