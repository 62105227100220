import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@sturm/lib/shared';
import { SturmConfirmDialogComponent } from './confirm-dialog.component';


@NgModule({
    declarations   : [
        SturmConfirmDialogComponent,
    ],
    imports        : [
        MatDialogModule,
        MatButtonModule,
        SharedModule,
        TranslocoModule,
    ],
    entryComponents: [
        SturmConfirmDialogComponent,
    ],
})
export class SturmConfirmDialogModule
{
}
