import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { SturmConfirmationModule } from '@sturm/services/confirmation';
import { SturmLoadingModule } from '@sturm/services/loading';
import { SturmMediaWatcherModule } from '@sturm/services/media-watcher/media-watcher.module';
import { SturmUtilsModule } from '@sturm/services/utils/utils.module';


@NgModule({
    imports  : [
        SturmConfirmationModule,
        SturmLoadingModule,
        SturmMediaWatcherModule,
        SturmUtilsModule,
    ],
    providers: [
        {
            // Disable 'theme' sanity check
            provide : MATERIAL_SANITY_CHECKS,
            useValue: {
                doctype: true,
                theme  : false,
                version: true,
            },
        },
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide : MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill',
            },
        },
    ],
})
export class SturmModule
{
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: SturmModule)
    {
        if (parentModule) {
            throw new Error('SturmModule has already been loaded. Import this module in the AppModule only!');
        }
    }
}
