import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SturmLoadingBarComponent } from '@sturm/components/loading-bar/loading-bar.component';


@NgModule({
    declarations: [
        SturmLoadingBarComponent,
    ],
    imports     : [
        CommonModule,
        MatProgressBarModule,
    ],
    exports     : [
        SturmLoadingBarComponent,
    ],
})
export class SturmLoadingBarModule
{
}
