import { ChangeDetectorRef, Directive, ElementRef, Input, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


export type AutocompleteSearchFunction = (filter: string | null) => Promise<void>;

@Directive({
    selector: 'input[autocompleteOptions]',
})
export class AutocompleteOptionsDirective implements OnInit, OnDestroy
{
    @Input()
    public search!: AutocompleteSearchFunction;

    #keyupSubscription!: Subscription;

    public constructor(
        @SkipSelf() private readonly _changeDetector: ChangeDetectorRef,
        private readonly _elementRef: ElementRef<HTMLInputElement>,
    )
    {
    }

    public ngOnInit(): void
    {
        this.#keyupSubscription = fromEvent(this._elementRef.nativeElement, 'keyup')
            .pipe(
                debounceTime(1000),
                distinctUntilChanged(),
            )
            .subscribe(async () =>
            {
                let value: string | null = this._elementRef.nativeElement.value;

                // eslint-disable-next-line eqeqeq
                if (value == '') {
                    value = null;
                }

                await this.search(value);

                this._changeDetector.detectChanges();
            });
    }

    public ngOnDestroy(): void
    {
        this.#keyupSubscription?.unsubscribe();
    }
}
