import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Material as IMaterial, MaterialFilter } from '@sturm/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable } from 'rxjs';
import { Material } from './material.model';


@Injectable({ providedIn: 'root' })
export class MaterialService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('materialUrl'));
    }

    loadByKey(materialId: number): Observable<Material>
    {
        return this.http.get<Material>(`${this.formatServiceUrl()}?key=${materialId}`);
    }

    delete(material: Material): Observable<Material>
    {
        return this.http.delete<Material>(`${this.formatServiceUrl()}?key=` + material.materialId);
    }

    create(material: Material): Observable<Material>
    {
        return this.http.post<Material>(this.formatServiceUrl('/create'), material);
    }

    merge(material: IMaterial): Observable<Material>
    {
        return this.http.post<Material>(this.formatServiceUrl('/merge'), material);
    }

    put(material: Material): Observable<Material>
    {
        return this.http.post<Material>(this.formatServiceUrl('/put'), material);
    }

    queryMaterial(filter: MaterialFilter): Observable<QueryResult<Material>>
    {
        return super.query<Material>(
            this.configService.getValue('baseUrl') +
            this.configService.getValue('b2bMaterialUrl') + '/query', filter);
    }

    update(material: Material): Observable<Material>
    {
        return this.http.post<Material>(this.formatServiceUrl('/update'), material);
    }
}
