import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@sturm/shared';
import { SettingsModule } from 'app/layout/common/settings/settings.module';
import { UserComponent } from 'app/layout/common/user/user.component';


@NgModule({
    declarations: [
        UserComponent,
    ],
    imports     : [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        SharedModule,
        MatSnackBarModule,
        SharedModule,
        TranslocoModule,
        SettingsModule,
    ],
    exports     : [
        UserComponent,
    ],
})
export class UserModule
{
}
