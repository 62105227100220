import { Component, HostBinding, Input, OnInit } from '@angular/core';


export type TooltipDirection = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';

@Component({
    selector   : 'lib-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls  : ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit
{
    @Input()
    public text!: string;

    @Input()
    public icon!: string;

    @Input()
    public direction: TooltipDirection = 'top-right';

    constructor()
    {
    }

    @HostBinding('class') get classList(): object
    {
        return {
            'flex'          : true,
            'justify-center': true,
            'items-center'  : true,
            'gap-4'         : true,
            'p-4'           : true,
            'h-fit'         : true,
            'bg-red-50'     : true,
        };
    }

    @HostBinding('style') get styles(): object
    {
        switch (this.direction) {
            case 'top-left':
                return {
                    'border-radius': '0px 32px 32px 32px',
                };
            case 'top-right':
                return {
                    'border-radius': '32px 0px 32px 32px',
                };
            case 'bottom-right':
                return {
                    'border-radius': '32px 32px 0px 32px',
                };
            case 'bottom-left':
                return {
                    'border-radius': '32px 32px 32px 0px',
                };
        }
    }

    ngOnInit(): void
    {
    }
}
