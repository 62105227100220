import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { SturmFullscreenModule } from '@sturm/components/fullscreen';
import { SturmLoadingBarModule } from '@sturm/components/loading-bar';
import { SturmNavigationModule } from '@sturm/components/navigation';
import { SharedModule } from '@sturm/shared';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { B2bLayoutComponent } from 'app/layout/layouts/b2b/b2b.component';


@NgModule({
    declarations: [
        B2bLayoutComponent,
    ],
    imports: [
        HttpClientModule,
        LanguagesModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        RouterModule,
        SearchModule,
        SharedModule,
        SturmFullscreenModule,
        SturmLoadingBarModule,
        SturmNavigationModule,
        TranslocoModule,
        UserModule,
    ],
    exports     : [
        B2bLayoutComponent,
    ],
})
export class B2bLayoutModule
{
}
