import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { MinMaxDatepickerComponent } from './min-max-datepicker.component';


@NgModule({
    declarations: [
        MinMaxDatepickerComponent,
    ],
    exports     : [
        MinMaxDatepickerComponent,
    ],
    imports     : [
        FormsModule,
        MatDatepickerModule,
        MatInputModule,
        TranslocoModule,
        MatIconModule,
    ],
})
export class MinMaxDatepickerModule
{
}
