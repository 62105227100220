//import { Profile as IProfile } from '@sturm/lib/domain';

import { Customer } from '@sturm/lib/admin';
import { Changed, Created } from '@yukawa/chain-base-angular-domain';


export interface IProfile extends Customer, Changed, Created
{
    customerId: string;
    customerNumber: string;
    discount: number;
    email: string;
    externalCustomerId: string;
    firstName: string;
    lang: string;
    lastName: string;
    mobile: string;
    priceListId: string;
    senderSystem: string;
    vatNumber: string;

    displayName: string;
    username: string;
}
