import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { appRoutes } from 'app/app.routing';


const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    initialNavigation        : 'disabled',
};

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, routerConfig)],
    exports: [RouterModule],
})
export class AppRoutingModule
{
}
