import { Injectable } from '@angular/core';
import { TemplateString } from '@awesome-nodes/object';
import { ConfigService, removeEmpty } from '@yukawa/chain-base-angular-client';
import { PlainObject } from 'simplytyped';
import { Order } from '../order';


@Injectable({ providedIn: 'root' })
export class CartService
{
    readonly sessionStoreKeyTemplate = TemplateString`${'sessionStoreKey'}=${'sessionStorePrefix'}order`;

    private _order!: Order;

    constructor(
        private readonly _configService: ConfigService,
    )
    {
    }

    public get order(): Order
    {
        return this._order;
    }

    private get orderSessionStoreKey(): string
    {
        return this.sessionStoreKeyTemplate({
            sessionStoreKey   : this._configService.getValue('sessionStoreKey'),
            sessionStorePrefix: this._configService.getValue('sessionStorePrefix'),
        });
    }

    load(): void
    {
        if (this._order == null) {
            this.restoreSessionOrderItems();
        }
    }

    save(): void
    {
        this.saveOrder();
    }

    clear(): void
    {
        this._order = {} as Order;
        this.saveOrder();
    }

    private restoreSessionOrderItems(): void
    {
        const items = localStorage.getItem(this.orderSessionStoreKey);
        if (items) {
            this._order = JSON.parse(items);
        }
        else {
            this._order = {} as Order;
        }
    }

    private saveOrder(): void
    {
        removeEmpty(this._order);

        for (const _key of Object.keys(this._order)) {
            if (!this._order.hasOwnProperty(_key)) {
                continue;
            }
            const propertyValue = (this._order as PlainObject)[_key];
            if (propertyValue === null || propertyValue === '') {
                delete (this._order as PlainObject)[_key];
            }
        }

        localStorage.setItem(
            this.orderSessionStoreKey,
            JSON.stringify(this._order),
        );
    }
}
