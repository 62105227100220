<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">

        <mat-icon>account_circle</mat-icon>

    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span transloco="AUTH.SIGNED_IN_AS"></span>
            <span class="mt-1.5 text-lg font-medium">{{username}}</span>
        </span>
    </button>
    <ng-container *ngIf="userIsAdmin">
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item>
            <mat-icon>account_circle</mat-icon>
            <span transloco="AUTH.PROFILE"></span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="toggleSettings()">
            <mat-icon>settings</mat-icon>
            <span transloco="SETTINGS.THEME"></span>
        </button>
    </ng-container>

    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span transloco="AUTH.LOGOUT.LOGOUT"></span>
    </button>
</mat-menu>
