import { Price as IPrice } from '@sturm/lib/domain';
import { formatNumber } from '@sturm/lib/shared';


export class MaterialPrice implements Object, IPrice
{
    public readonly currency: string;
    public readonly customerId?: string;
    public readonly description: string;
    public readonly discount: number;
    public readonly discountedValue: number;
    public readonly priceListId: string;
    public readonly retailValue: number;
    public readonly value: number;
    public readonly wholesaleValue: number;

    private _quantity: number = 1;

    public constructor(price: IPrice, discount?: number)
    {
        this.currency        = price.currency || '';
        this.customerId      = price.customerId;
        this.description     = price.description as string;
        this.discount        = discount ?? 0;
        this.discountedValue = price.discountedValue ?? 0;
        this.priceListId     = price.priceListId as string;
        this.retailValue     = price.retailValue ?? 0;
        this.value           = price.value ?? 0;
        this.wholesaleValue  = price.wholesaleValue ?? 0;
    }

    //region Public Properties

    public get quantity(): number
    {
        return this._quantity;
    }

    public set quantity(value: number)
    {
        this._quantity = value || 1;
    }

    public get discountTotal(): number
    {
        return MaterialPrice.mercantileRound(this.discount > 0
            ? ((this.value - this.discountedValue) || this.value / 100 * this.discount) * this._quantity
            : 0);
    }

    public get valueTotal(): number
    {
        return MaterialPrice.mercantileRound(this.value * this._quantity);
    }

    public get retailValueTotal(): number
    {
        return MaterialPrice.mercantileRound(this.retailValue * this._quantity);
    }

    public get wholesaleValueTotal(): number
    {
        return MaterialPrice.mercantileRound(this.wholesaleValue * this._quantity);
    }

    public get total(): number
    {
        return MaterialPrice.mercantileRound(this.discountedValue
            ? this.discountedValue * this._quantity
            : this.value * this._quantity - this.discountTotal);
    }

    //endregion

    public static discountTotal(
        field: keyof Pick<MaterialPrice, 'discountTotal' | 'retailValueTotal' | 'wholesaleValueTotal'>,
        ...prices: Array<MaterialPrice>): string
    {
        const total    = formatNumber(this.mercantileRound(prices.reduce((previous, next) =>
        {
            if (field === 'discountTotal') {
                previous += next.discountTotal;
            }
            else {
                const value = next['valueTotal'] - next[field];
                // Skip non negative values
                if (value < 0) {
                    previous += value;
                }
            }

            return previous;
        }, 0)));
        const currency = prices[0]?.currency;

        return currency ? `${currency} ${total}` : String(total);
    }

    /**
     * Returns the final price formatted in the current language including currency.
     *
     * @param  field
     * @param  prices
     */
    public static sumTotal(
        field: keyof Pick<MaterialPrice, 'total' | 'valueTotal' | 'retailValueTotal' | 'wholesaleValueTotal'>,
        ...prices: Array<MaterialPrice>): string
    {
        const total    = formatNumber(this.mercantileRound(prices.reduce((previous, next) =>
        {
            previous += next[field];
            return previous;
        }, 0)));
        const currency = prices[0]?.currency;

        return currency ? `${currency} ${total}` : String(total);
    }

    /**
     * Returns the final price formatted in english number format.
     *
     * @param  field
     * @param  prices
     */
    public static sumTotalValue(
        field: keyof Pick<MaterialPrice, 'total' | 'valueTotal' | 'retailValueTotal' | 'wholesaleValueTotal'>,
        ...prices: Array<MaterialPrice>): string
    {
        return formatNumber(this.mercantileRound(prices.reduce((previous, next) =>
        {
            previous += next[field];
            return previous;
        }, 0)), 2, 'en');
    }

    protected static mercantileRound(value: number): number
    {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }

    public toString(
        field: keyof Pick<MaterialPrice, 'total' | 'value' | 'discountTotal' | 'retailValue' | 'wholesaleValue'> = 'value',
    ): string
    {
        const value = formatNumber(this[field]);
        return this.currency ? `${this.currency} ${value}` : value;
    }

}
