<mat-form-field
    class="flooring-mat-dense flooring-mat-no-subscript flex-auto w-full max-w-100">
    <mat-label class="normal-case">{{ label }}</mat-label>
    <input matInput [(ngModel)]="date" (dateChange)="dateChanged($event)"
           [matDatepicker]="picker"
           [min]="minDate"
           [max]="maxDate"
           name="delivery-date"
           autocomplete="off"
           [disabled]="disabled"
    >
    <mat-hint>{{ hint }}</mat-hint>
    <mat-datepicker-toggle matIconSuffix class="-mr-4" [for]="picker">
        <mat-icon matDatepickerToggleIcon svgIcon="sturm:bookings"
                  class="icon-size-8 text-primary"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
