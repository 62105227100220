<form novalidate [formGroup]="detailsForm" *ngIf="detailsForm">
    <ng-container *ngFor="let _formField of formFieldConfig;">
        <p [ngSwitch]="_formField.detail.type" class="mt-4">

            <ng-container *ngSwitchCase="'number'">
                <mat-form-field class="sturm-mat-bold sturm-mat-no-subscript w-full">
                    <mat-label>{{ _formField.detail.label | transloco }}</mat-label>
                    <mat-icon matPrefix *ngIf="_formField.control.hasError('error')"
                              class="text-warn"
                              [matTooltip]="getErrorMessage(_formField.control.errors)"
                              [matTooltipClass]="'error-tooltip'">error
                    </mat-icon>
                    <input matInput type="number"
                           [name]=_formField.detail.key
                           [formControl]=_formField.control
                           [formControlName]=_formField.detail.key
                           [placeholder]="('QUERY_TABLE.ENTER_FIELD' | transloco:{
                               value: (_formField.detail.label | transloco)
                           })">
                </mat-form-field>
                <mat-error *ngIf="_formField.control.hasError('required')">
                    {{ "QUERY_TABLE.REQUIRED" | transloco }}
                </mat-error>
            </ng-container>

            <ng-container *ngSwitchCase="'boolean'">
                <mat-icon *ngIf="_formField.control.hasError('error')"
                          class="text-warn mr-2"
                          [matTooltip]="getErrorMessage(_formField.control.errors)"
                          [matTooltipClass]="'error-tooltip'">error
                </mat-icon>
                <mat-checkbox
                    [name]=_formField.detail.key
                    [formControl]=_formField.control
                    [formControlName]=_formField.detail.key>
                    {{ _formField.detail.label | transloco }}
                </mat-checkbox>
                <mat-error *ngIf="_formField.control.hasError('required')">
                    {{ "QUERY_TABLE.REQUIRED" | transloco }}
                </mat-error>
            </ng-container>

            <ng-container *ngSwitchCase="'text'">
                <mat-form-field class="sturm-mat-bold sturm-mat-no-subscript w-full">
                    <mat-label>{{ _formField.detail.label | transloco }}</mat-label>
                    <mat-icon matPrefix *ngIf="_formField.control.hasError('error')"
                              class="text-warn"
                              [matTooltip]="getErrorMessage(_formField.control.errors)"
                              [matTooltipClass]="'error-tooltip'">error
                    </mat-icon>
                    <input matInput type="text" #text
                           [minlength]=_formField.detail.minLength
                           [maxlength]=_formField.detail.maxLength
                           [name]=_formField.detail.key
                           [formControl]=_formField.control
                           [formControlName]=_formField.detail.key
                           [placeholder]="('QUERY_TABLE.ENTER_FIELD' | transloco:{
                                       value:( _formField.detail.label | transloco)
                                   })"
                           [autocomplete]="_formField.detail.autocomplete || 'on'"
                           focused #isFocused="hasFocus">
                    <mat-hint *ngIf="_formField.detail.maxLength && isFocused.hasFocus" matSuffix align="end"
                    >{{text.value.length || 0}}/{{_formField.detail.maxLength}}</mat-hint>
                    <mat-icon matSuffix emojiTooltip [virtualize]="false" (emojiClick)="addEmoji($event)"
                              *ngIf="_formField.detail.emojiSupport"
                              class="emoji-button"
                              (click)="selectEmoji(text)"
                              aria-hidden="false"
                              aria-label="Emoji picker button"
                    >emoji_emotions
                    </mat-icon>
                    <mat-error *ngIf="_formField.control.hasError('required')">
                        {{ "QUERY_TABLE.REQUIRED" | transloco }}
                    </mat-error>
                    <mat-error *ngIf="_formField.control.hasError('minlength')">
                        {{ "QUERY_TABLE.MIN_LENGTH" | transloco: {minLength: _formField.detail.minLength} }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'autocomplete'">
                <mat-form-field class="sturm-mat-bold sturm-mat-no-subscript w-full">
                    <mat-label>{{ _formField.detail.label | transloco }}</mat-label>
                    <mat-icon matPrefix *ngIf="_formField.control.hasError('error')"
                              class="text-warn"
                              [matTooltip]="getErrorMessage(_formField.control.errors)"
                              [matTooltipClass]="'error-tooltip'">error
                    </mat-icon>
                    <input matInput type="text" #autocomplete
                           [name]=_formField.detail.key
                           [formControl]=_formField.control
                           [formControlName]=_formField.detail.key
                           [matAutocomplete]="auto"
                           [placeholder]="('QUERY_TABLE.ENTER_FIELD' | transloco:{
                                       value:( _formField.detail.label | transloco)
                                   })" autocompleteOptions [search]="_formField.detail.autocompleteSearch">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of _formField.detail.autocompleteOptions$ | async"
                                    [value]="option.value">
                            {{ option.value }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="_formField.control.hasError('required')">
                        {{ "QUERY_TABLE.REQUIRED" | transloco }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'version'">
                <mat-form-field class="sturm-mat-bold sturm-mat-no-subscript w-full">
                    <mat-label>{{ _formField.detail.label | transloco }}</mat-label>
                    <mat-icon matPrefix *ngIf="_formField.control.hasError('error')"
                              class="text-warn"
                              [matTooltip]="getErrorMessage(_formField.control.errors)"
                              [matTooltipClass]="'error-tooltip'">error
                    </mat-icon>
                    <input matInput type="text" #text
                           [name]=_formField.detail.key
                           [formControl]=_formField.control
                           [formControlName]=_formField.detail.key
                           [placeholder]="('QUERY_TABLE.ENTER_FIELD' | transloco:{
                                       value:( _formField.detail.label | transloco)
                                   })"
                           (blur)="onVersionBlur($event, _formField.control)">
                    <mat-icon matSuffix emojiTooltip [virtualize]="false" (emojiClick)="addEmoji($event)"
                              *ngIf="_formField.detail.emojiSupport"
                              class="emoji-button"
                              (click)="selectEmoji(text)"
                              aria-hidden="false"
                              aria-label="Emoji picker button"
                    >emoji_emotions
                    </mat-icon>
                    <mat-error *ngIf="_formField.control.hasError('required')">
                        {{ "QUERY_TABLE.REQUIRED" | transloco }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'multiline'">
                <mat-form-field class="sturm-mat-bold sturm-mat-no-subscript w-full">
                    <mat-label>{{ _formField.detail.label | transloco }}</mat-label>
                    <mat-icon matPrefix *ngIf="_formField.control.hasError('error')"
                              class="text-warn"
                              [matTooltip]="getErrorMessage(_formField.control.errors)"
                              [matTooltipClass]="'error-tooltip'">error
                    </mat-icon>
                    <textarea matInput #multiline
                              aria-multiline="true"
                              [name]=_formField.detail.key
                              [formControl]=_formField.control
                              [formControlName]="_formField.detail.key"
                              [placeholder]="('QUERY_TABLE.ENTER_FIELD' | transloco:{
                                          value:( _formField.detail.label | transloco)
                                      })">
                            </textarea>
                    <mat-icon matSuffix emojiTooltip [virtualize]="false" (emojiClick)="addEmoji($event)"
                              *ngIf="_formField.detail.emojiSupport"
                              class="emoji-button"
                              (click)="selectEmoji(multiline)"
                              aria-hidden="false"
                              aria-label="Emoji picker button"
                    >emoji_emotions
                    </mat-icon>
                    <mat-error *ngIf="_formField.control.hasError('required')">
                        {{ "QUERY_TABLE.REQUIRED" | transloco }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'select'">
                <mat-form-field class="sturm-mat-bold sturm-mat-no-subscript w-full">
                    <mat-label>{{ _formField.detail.label | transloco }}</mat-label>
                    <mat-icon matPrefix *ngIf="_formField.control.hasError('error')"
                              class="text-warn"
                              [matTooltip]="getErrorMessage(_formField.control.errors)"
                              [matTooltipClass]="'error-tooltip'">error
                    </mat-icon>
                    <mat-select [formControl]=_formField.control
                                [placeholder]="('QUERY_TABLE.PLEASE_SELECT' | transloco)">
                        <mat-option *ngFor="let _option of ((_formField.detail.options$ | async)
                                           || _formField.detail.options);"
                                    [value]="_option.value">{{ _option.name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="_formField.control.hasError('required')">
                        {{ "QUERY_TABLE.REQUIRED" | transloco }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'multiselect'">
                <mat-form-field class="sturm-mat-bold sturm-mat-no-subscript w-full">
                    <mat-label>{{ _formField.detail.label | transloco }}</mat-label>
                    <mat-icon matPrefix *ngIf="_formField.control.hasError('error')"
                              class="text-warn"
                              [matTooltip]="getErrorMessage(_formField.control.errors)"
                              [matTooltipClass]="'error-tooltip'">error
                    </mat-icon>
                    <mat-select multiple
                                [formControl]=_formField.control
                                [placeholder]="('QUERY_TABLE.PLEASE_SELECT' | transloco)">
                        <mat-option *ngFor="let _option of _formField.detail.options"
                                    [value]="_option.value">{{ _option.name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="_formField.control.hasError('required')">
                        {{ "QUERY_TABLE.REQUIRED" | transloco }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'date'">
                <mat-form-field class="sturm-mat-bold sturm-mat-no-subscript w-full">
                    <mat-label>{{ _formField.detail.label | transloco }}</mat-label>
                    <mat-icon matPrefix *ngIf="_formField.control.hasError('error')"
                              class="text-warn"
                              [matTooltip]="getErrorMessage(_formField.control.errors)"
                              [matTooltipClass]="'error-tooltip'">error
                    </mat-icon>
                    <input matInput [matDatepicker]="picker"
                           [min]="_formField.detail.minValue"
                           [max]="_formField.detail.maxValue"
                           [name]=_formField.detail.key
                           [formControl]=_formField.control
                           [formControlName]=_formField.detail.key>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="_formField.control.hasError('required')">
                        {{ "QUERY_TABLE.REQUIRED" | transloco }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'url'">
                <mat-form-field class="sturm-mat-bold sturm-mat-no-subscript w-full">
                    <mat-label>{{ _formField.detail.label | transloco }}</mat-label>
                    <mat-icon matPrefix *ngIf="_formField.control.hasError('error')"
                              class="text-warn"
                              [matTooltip]="getErrorMessage(_formField.control.errors)"
                              [matTooltipClass]="'error-tooltip'">error
                    </mat-icon>
                    <input matInput type="url"
                           [name]=_formField.detail.key
                           [formControl]=_formField.control
                           [formControlName]=_formField.detail.key
                           [placeholder]="'https://'">
                    <mat-error *ngIf="_formField.control.hasError('required')">
                        {{ "QUERY_TABLE.REQUIRED" | transloco }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'image'">
                <mat-form-field class="sturm-mat-bold sturm-mat-no-subscript w-full">
                    <mat-label>{{ _formField.detail.label | transloco }}</mat-label>
                    <mat-icon matPrefix *ngIf="_formField.control.hasError('error')"
                              class="text-warn"
                              [matTooltip]="getErrorMessage(_formField.control.errors)"
                              [matTooltipClass]="'error-tooltip'">error
                    </mat-icon>
                    <input matInput type="url"
                           [name]=_formField.detail.key
                           [formControl]=_formField.control
                           [formControlName]=_formField.detail.key
                           [placeholder]="'https://'">
                    <mat-error *ngIf="_formField.control.hasError('required')">
                        {{ "QUERY_TABLE.REQUIRED" | transloco }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'tag'">

                <mat-form-field class="w-full" appearance="fill" (click)="selectTags.emit()">
                    <mat-label transloco="VIDEO.RECORD.DETAILS.TAGS"></mat-label>
                    <mat-icon matPrefix *ngIf="_formField.control.hasError('error')"
                              class="text-warn"
                              [matTooltip]="getErrorMessage(_formField.control.errors)"
                              [matTooltipClass]="'error-tooltip'">error
                    </mat-icon>
                    <mat-chip-list formArrayName="interests" required label="Tag Selection" class="mt-2 mb-2"
                                   placeholder="{{ 'VIDEO.RECORD.DETAILS.TAGS_PLACEHOLDER' | transloco }}">
                        <mat-chip class="bg-gray-200"
                                  *ngFor="let _option of ((_formField.detail.options$ | async)
                                           || _formField.detail.options);"
                                  (removed)="_formField.detail.selectOptions.remove(_option)">{{ _option.name }}
                            <button mat-icon-button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                    </mat-chip-list>
                    <mat-icon matSuffix svgIcon="sturm:system-plus-circle"></mat-icon>
                    <mat-error *ngIf="_formField.control.hasError('required')">
                        {{ "QUERY_TABLE.REQUIRED" | transloco }}
                    </mat-error>
                </mat-form-field>

            </ng-container>

        </p>
    </ng-container>
</form>
