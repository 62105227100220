import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SturmLoadingInterceptor } from '@sturm/services/loading/loading.interceptor';


@NgModule({
    providers: [
        {
            provide : HTTP_INTERCEPTORS,
            useClass: SturmLoadingInterceptor,
            multi   : true,
        },
    ],
})
export class SturmLoadingModule
{
}
