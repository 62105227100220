<!-- Item wrapper -->
<div
    class="sturm-horizontal-navigation-item-wrapper"
    [class.sturm-horizontal-navigation-item-has-subtitle]="!!item.subtitle"
    [ngClass]="item.classes?.wrapper">

    <!-- Item with an internal link -->
    <ng-container *ngIf="item.link && !item.externalLink && !item.function && !item.disabled">
        <div
            class="sturm-horizontal-navigation-item"
            [ngClass]="{'sturm-horizontal-navigation-item-active-forced': item.active}"
            [routerLink]="[item.link]"
            [routerLinkActive]="'sturm-horizontal-navigation-item-active'"
            [routerLinkActiveOptions]="isActiveMatchOptions"
            [matTooltip]="item.tooltip || ''">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an external link -->
    <ng-container *ngIf="item.link && item.externalLink && !item.function && !item.disabled">
        <a
            class="sturm-horizontal-navigation-item"
            [href]="item.link"
            [target]="item.target || '_self'"
            [matTooltip]="item.tooltip || ''">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with a function -->
    <ng-container *ngIf="!item.link && item.function && !item.disabled">
        <div
            class="sturm-horizontal-navigation-item"
            [ngClass]="{'sturm-horizontal-navigation-item-active-forced': item.active}"
            [matTooltip]="item.tooltip || ''"
            (click)="item.function(item)">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an internal link and function -->
    <ng-container *ngIf="item.link && !item.externalLink && item.function && !item.disabled">
        <div
            class="sturm-horizontal-navigation-item"
            [ngClass]="{'sturm-horizontal-navigation-item-active-forced': item.active}"
            [routerLink]="[item.link]"
            [routerLinkActive]="'sturm-horizontal-navigation-item-active'"
            [routerLinkActiveOptions]="isActiveMatchOptions"
            [matTooltip]="item.tooltip || ''"
            (click)="item.function(item)">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an external link and function -->
    <ng-container *ngIf="item.link && item.externalLink && item.function && !item.disabled">
        <a
            class="sturm-horizontal-navigation-item"
            [href]="item.link"
            [target]="item.target || '_self'"
            [matTooltip]="item.tooltip || ''"
            (click)="item.function(item)"
            mat-menu-item>
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with a no link and no function -->
    <ng-container *ngIf="!item.link && !item.function && !item.disabled">
        <div
            class="sturm-horizontal-navigation-item"
            [ngClass]="{'sturm-horizontal-navigation-item-active-forced': item.active}"
            [matTooltip]="item.tooltip || ''">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item is disabled -->
    <ng-container *ngIf="item.disabled">
        <div class="sturm-horizontal-navigation-item sturm-horizontal-navigation-item-disabled">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

</div>

<!-- Item template -->
<ng-template #itemTemplate>

    <!-- Icon -->
    <ng-container *ngIf="item.icon">
        <mat-icon
            class="sturm-horizontal-navigation-item-icon"
            [ngClass]="item.classes?.icon"
            [svgIcon]="item.icon"></mat-icon>
    </ng-container>

    <!-- Badge -->
    <ng-container *ngIf="item.badge">
        <div class="sturm-horizontal-navigation-item-badge">
            <div
                class="sturm-horizontal-navigation-item-badge-content relative"
                [ngClass]="item.badge.classes">
                <span class="flex absolute h-6 w-6 nav-badge" *ngIf="item.badge.title &&
                item.badge.title !== '0'">
                  <span
                      class="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#646464] opacity-75"></span>
                  <span
                      class="relative inline-flex rounded-full h-6 w-6 bg-[#171717] flex items-center justify-center font-bold">{{item.badge.title}}</span>
                </span>
            </div>
        </div>
    </ng-container>

    <!-- Title & Subtitle -->
    <div class="sturm-horizontal-navigation-item-title-wrapper {{ titleClass }}">
        <div class="sturm-horizontal-navigation-item-title">
            <span [ngClass]="item.classes?.title" [transloco]="item.title">
            </span>
        </div>
        <ng-container *ngIf="item.subtitle">
            <div class="sturm-horizontal-navigation-item-subtitle text-hint">
                <span [ngClass]="item.classes?.subtitle">
                    {{item.subtitle}}
                </span>
            </div>
        </ng-container>
    </div>

</ng-template>
