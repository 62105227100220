import { AfterViewInit, Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    CartService,
    MaterialPrice,
    Order,
    OrderItem,
    OrderItemDatasource,
    OrderItemService,
    OrderItemTableEntry,
    OrderService,
} from '@sturm/lib/admin';
import { fadeIn, fadeOut } from '@sturm/lib/animations';
import { ProfileService } from '@sturm/lib/profile';
import { cloneDeep } from 'lodash-es';
import { lastValueFrom } from 'rxjs';


export type SubmitOrderDialogData = {
    order: Order;
    orderItems: Array<OrderItem>;
};

@Component({
    selector   : 'app-submit-material-order',
    templateUrl: './submit-order.component.html',
    styleUrls  : ['./submit-order.component.scss'],
    animations : [
        fadeIn, fadeOut,
    ],
})
export class SubmitMaterialOrderComponent implements AfterViewInit
{
    public uploading: boolean = true;

    constructor(
        private readonly _cartService: CartService,
        private readonly _dialog: MatDialogRef<SubmitMaterialOrderComponent>,
        private readonly _profileService: ProfileService,
        private readonly _orderService: OrderService,
        private readonly _orderItemDatasource: OrderItemDatasource,
        private readonly _orderItemService: OrderItemService,
        @Inject(MAT_DIALOG_DATA) public data: SubmitOrderDialogData,
    )
    {
    }

    async ngAfterViewInit(): Promise<void>
    {
        this.data.order.items      = cloneDeep(this.data.orderItems);
        this.data.order.conditions = [
            {
                type    : 'MainPrice',
                value   : MaterialPrice.sumTotalValue(
                    'total',
                    ...this._orderItemDatasource.entries.map((entry: OrderItemTableEntry) => entry.price),
                ).replace(',', ''),
                currency: this.data.orderItems[0].product.prices[0].currency,
            },
        ];
        this.data.order.customerId = this._profileService.profile.customerId;

        // remove cached product instance
        this.data.order.items.forEach((item) =>
        {
            delete item.id;
            delete item.cartDate;
            delete item.discount;
            delete item.ean;
            delete item.product;
            delete item.total;
        });

        await lastValueFrom(this._orderService.send(this.data.order));

        this._orderItemService.clear();
        this._cartService.clear();

        setTimeout(() =>
        {
            this.uploading = false;

            setTimeout(() =>
            {
                this._dialog.close();
            }, 3000);
        }, 1000);
    }

    @HostListener('click')
    click(): void
    {
        this._dialog.close();
    }
}
