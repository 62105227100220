<div *ngFor="let _detail of (details$ | async)"
     class="flex py-3" [ngClass]="{
        'justify-between': viewDirection === 'vertical',
        'flex-col': isLongValue(_detail.value) || viewDirection === 'horizontal'
    }">

    <div class="text-secondary">{{ _detail.label | transloco }}</div>

    <ng-container [ngSwitch]="_detail.type">

        <div *ngSwitchCase="'date'" @fadeIn>{{ _detail.value | dateFormat: 'L' }}</div>

        <div *ngSwitchCase="'url'" class="break-all">
            <a [href]="_detail.value" @fadeIn>{{ _detail.value }}</a>
        </div>

        <mat-checkbox *ngSwitchCase="'boolean'" [checked]="_detail.value" disabled></mat-checkbox>

        <div *ngSwitchDefault [ngClass]="{
                            'break-normal': containsSpaces(_detail.value),
                            'break-all': !containsSpaces(_detail.value)
                        }">
            <ng-container [ngSwitch]="_detail.markdownSupport">
                                <span @fadeIn *ngSwitchCase="true"
                                      class="{{ _detail.class }}"
                                      [matTooltip]="_detail.value"
                                      [innerHTML]="getDetailValue(_detail)"></span>
                <span @fadeIn *ngSwitchCase="false"
                      class="{{ _detail.class }}"
                      [matTooltip]="_detail.value">{{ getDetailValue(_detail) }}</span>
            </ng-container>

        </div>

    </ng-container>

</div>
