import { NgModule } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import {
    AutocompleteOptionsDirective,
    BackButtonDirective,
    ElapsedDateDirective,
    EmojiTooltipDirective,
    FocusedDirective,
    MatMultilineTabDirective,
    RenderIfVisibleDirective,
} from './directive';
import {
    DateFormatPipe,
    DateSuffixPipe,
    ElapsedDatePipe,
    EmojiPipe,
    FindByKeyPipe,
    HumanizedDatePipe,
    LikesPipe,
    VersionPipe,
} from './pipe';
import { MarkedPipe } from './pipe/marked.pipe';


const components = [
    AutocompleteOptionsDirective,
    BackButtonDirective,
    DateFormatPipe,
    DateSuffixPipe,
    ElapsedDateDirective,
    ElapsedDatePipe,
    EmojiPipe,
    EmojiTooltipDirective,
    FindByKeyPipe,
    FocusedDirective,
    HumanizedDatePipe,
    LikesPipe,
    MarkedPipe,
    MatMultilineTabDirective,
    RenderIfVisibleDirective,
    VersionPipe,
];

@NgModule({
    imports     : [
        PickerModule,
    ],
    declarations: components,
    exports     : components,
})
export class SharedModule
{
}
