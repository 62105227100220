import { Injectable } from '@angular/core';
import { IProfile } from '@sturm/lib/profile';
import { MockApiService } from '@yukawa/chain-base-angular-client';
import { SessionService } from '@yukawa/chain-main-angular-session';


@Injectable({
    providedIn: 'root',
})
export class ProfileMockApi
{
    /**
     * Constructor
     */
    constructor(
        private readonly _sturmMockApiService: MockApiService,
        private readonly _sessionService: SessionService,
    )
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {

        // -----------------------------------------------------------------------------------------------------
        // @ User - GET
        // -----------------------------------------------------------------------------------------------------
        this._sturmMockApiService
            .onGet('api/profile-service/profiles')
            .reply(() =>
            {
                const info = this._sessionService.session.user.info;
                return [
                    200, {
                        username   : info.username,
                        displayName: [info.person.firstName, info.person.lastName]
                            .map(s => s?.trim())
                            .filter(s => !!s).join(' ') || info.username,
                    } as IProfile,
                ];
            });
    }
}
