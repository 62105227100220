import { SturmNavigationItem } from '@sturm/components/navigation';
import { Role, UserService } from '@sturm/lib/profile';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { environment } from 'environments';


const navigation = new Array<SturmNavigationItem>(
    {
        id     : 'material',
        title  : 'MATERIAL.TITLE',
        type   : 'basic',
        icon   : 'sturm:store',
        classes: {
            icon: 'icon-size-10',
        },
        link   : '/plp/shop',
        hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.shop),
    },
    {
        id     : 'cart',
        title  : 'CART.TITLE',
        type   : 'basic',
        icon   : 'sturm:cart',
        classes: {
            icon: 'transition-all icon-size-10 -mt-0.5',
            nav : {
                mobileNavigation: {
                    title: 'hidden md:block',
                },
            },
        },
        link   : '/plp/cart',
        badge  : {
            title  : '',
            classes: 'font-thin text-white mt-1 -ml-16 text-xl',
        },
        hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.shop),
    },
    {
        id     : 'user',
        title  : 'USER.TITLE',
        type   : 'basic',
        icon   : 'heroicons_outline:users',
        classes: {
            icon: 'icon-size-10',
        },
        hidden : (): boolean => !AppInjector.get(UserService).user?.groupContexts.some(groupContext => groupContext.groups.find(
            group => group === 'ADMINS')),
        link   : '/user',
    },
);

if (!environment.production) {
}

export const defaultNavigation: SturmNavigationItem[]    = navigation;
export const compactNavigation: SturmNavigationItem[]    = navigation;
export const futuristicNavigation: SturmNavigationItem[] = navigation;
export const horizontalNavigation: SturmNavigationItem[] = navigation;
