import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { SturmNavigationService } from '@sturm/components/navigation/navigation.service';
import { SturmNavigationItem } from '@sturm/components/navigation/navigation.types';
import { sturmAnimations } from '@sturm/lib/animations';
import { SturmUtilsService } from '@sturm/services/utils/utils.service';
import { ReplaySubject, Subject } from 'rxjs';


@Component({
    selector       : 'sturm-horizontal-navigation',
    templateUrl    : './horizontal.component.html',
    styleUrls      : ['./horizontal.component.scss'],
    animations     : sturmAnimations,
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'sturmHorizontalNavigation',
})
export class SturmHorizontalNavigationComponent implements OnChanges, OnInit, OnDestroy
{
    @Input() name: string;
    @Input() navigation: SturmNavigationItem[];

    onRefreshed: ReplaySubject<boolean>   = new ReplaySubject<boolean>(1);
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _sturmNavigationService: SturmNavigationService,
        private _sturmUtilsService: SturmUtilsService,
    )
    {
        this.name = this._sturmUtilsService.randomId();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        // Navigation
        if ('navigation' in changes) {
            // Mark for check
            this._changeDetectorRef.markForCheck();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Make sure the name input is not an empty string
        if (this.name === '') {
            this.name = this._sturmUtilsService.randomId();
        }

        // Register the navigation component
        this._sturmNavigationService.registerComponent(this.name, this);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Deregister the navigation component from the registry
        this._sturmNavigationService.deregisterComponent(this.name);

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh the component to apply the changes
     */
    refresh(): void
    {
        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Execute the observable
        this.onRefreshed.next(true);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }
}
