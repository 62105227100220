import { LangDefinition, TranslocoService } from '@ngneat/transloco';
import { AppInjector } from '@yukawa/chain-base-angular-client';


export const formatNumber = (value: number, decimals: number = 2, language?: string): string =>
{
    const locales    = AppInjector.get(TranslocoService).getAvailableLangs().map((lang: string | LangDefinition) => typeof lang === 'string' ? lang : lang.id);
    const activeLang = language || AppInjector.get(TranslocoService).getActiveLang();
    locales.splice(locales.findIndex((locale: string) => locale === activeLang), 1);
    locales.splice(0, 0, activeLang);

    return value.toLocaleString(locales, {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
    });
};
