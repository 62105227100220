<!-- Loading bar -->
<sturm-loading-bar></sturm-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <sturm-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container sturmVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <mat-icon [svgIcon]="'sturm:logo'" class="icon-size-24"></mat-icon>
            </div>
        </ng-container>
    </sturm-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 border-b-2.5 border-b-primary dark:border-b bg-card dark:bg-sturm-darkerBlue print:hidden overflow-hidden">

        <ng-container [ngTemplateOutlet]="logoBackground"
                      [ngTemplateOutletContext]="{ className: 'opacity-20 contrast-150', y: '-68%' }"></ng-container>

        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <!-- Light version -->
                    <mat-icon [svgIcon]="'sturm:logo'" class="dark:hidden w-26 text-primary"></mat-icon>
                    <!-- Dark version -->
                    <mat-icon [svgIcon]="'sturm:logo'" class="hidden dark:flex w-26 text-primary"></mat-icon>
                </div>
                <mat-icon [svgIcon]="'sturm:icon'" class="flex lg:hidden w-8"></mat-icon>
            </div>
            <!-- Horizontal navigation -->
            <sturm-horizontal-navigation
                class="mr-2"
                [name]="'mainNavigation'"
                [navigation]="navigation.horizontal"></sturm-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <languages></languages>
            <sturm-fullscreen class="hidden md:block"></sturm-fullscreen>
            <!-- <shortcuts></shortcuts> -->

            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden overflow-hidden">
        <span class="font-medium text-sturm-footer">Sturm &copy; {{currentYear}}</span>
        <mat-icon svgIcon="sturm:logo" class="text-sturm-footer w-22 ml-auto mr-auto"></mat-icon>
        <span class="font-medium text-sturm-footer">v {{ version }}</span>
        <ng-container [ngTemplateOutlet]="logoBackground" [ngTemplateOutletContext]="{ y: '-72%'}"></ng-container>
    </div>

</div>

<ng-template #logoBackground let-className='className' let-y="y">
    <!--<img class="absolute left-0 top-0 right-0 bottom-0 -z-10 object-cover {{ className }}" alt=""
         [ngStyle]="{ transform: 'translate(0,' + y + ')'}"
         src="assets/images/Header.jpeg"/>-->
</ng-template>
