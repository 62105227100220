/* eslint-disable @typescript-eslint/member-ordering */
import { AppInjector, Entity, removeEmpty } from '@yukawa/chain-base-angular-client';
import { Change } from '@yukawa/chain-base-angular-domain';
import { IProfile as IProfile } from './profile.model';
import { UserService } from './user.service';


export class Profile extends Entity<IProfile> implements IProfile
{
    public change!: Required<Change>;
    public created!: Required<Change>;
    public customerId!: string;
    public customerNumber!: string;
    public discount!: number;
    public email!: string;
    public externalCustomerId!: string;
    public firstName!: string;
    public lang!: string;
    public lastName!: string;
    public mobile!: string;
    public priceListId!: string;
    public senderSystem!: string;
    public vatNumber!: string;
    public displayName!: string;
    public username!: string;
    public imageUrl!: string;
    public image!: null;
    public verified!: boolean;

    constructor(initialData: IProfile)
    {
        super(initialData as IProfile);

        this.displayName = removeEmpty([initialData.firstName, initialData.lastName]).join(' ').trim();
    }

    //region Public Properties

    public get key(): string
    {
        return this.username;
    }

    public get isAdmin(): boolean
    {
        return this.username === 'admin';
    }

    public get isCurrentUser(): boolean
    {
        return AppInjector.get(UserService).user?.username === this.username;
    }

    //endregion

    public updateFromJson(data: IProfile & { verifiedDate: Date }): void
    {
        this.setFromJson(data, [
            'change',
            'created',
            'customerId',
            'customerNumber',
            'discount',
            'email',
            'externalCustomerId',
            'firstName',
            'lang',
            'lastName',
            'mobile',
            'priceListId',
            'senderSystem',
            'vatNumber',
            'displayName',
            'username',
        ], undefined, {});
    }

    public toJson(): IProfile
    {
        return this.toJsonWithKeys([
            'change',
            'created',
            'customerId',
            'customerNumber',
            'discount',
            'email',
            'externalCustomerId',
            'firstName',
            'lang',
            'lastName',
            'mobile',
            'priceListId',
            'senderSystem',
            'vatNumber',
            'displayName',
            'username',
        ], {});
    }
}
