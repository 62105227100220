import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { SturmFullscreenModule } from '@sturm/components/fullscreen';
import { SturmLoadingBarModule } from '@sturm/components/loading-bar';
import { SturmNavigationModule } from '@sturm/components/navigation';
import { SharedModule } from '@sturm/shared';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { DenseLayoutComponent } from 'app/layout/layouts/vertical/dense/dense.component';


@NgModule({
    declarations: [
        DenseLayoutComponent,
    ],
    imports     : [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        SturmFullscreenModule,
        SturmLoadingBarModule,
        SturmNavigationModule,
        LanguagesModule,
        SearchModule,
        UserModule,
        SharedModule,
    ],
    exports     : [
        DenseLayoutComponent,
    ],
})
export class DenseLayoutModule
{
}
