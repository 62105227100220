import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Material, OrderItemDatasource, OrderItemTableEntry } from '@sturm/lib/admin';
import { QueryTableStore } from '@sturm/lib/components';
import { Version } from '@sturm/lib/config';
import { MaterialFilter } from '@sturm/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable, Subject, takeUntil } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';


export class MaterialCartQueryStore extends QueryTableStore<OrderItemTableEntry>
{
    readonly #unsubscribeAll = new Subject();

    private _list = AppInjector.get(OrderItemDatasource);

    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: MaterialFilter)
    {
        super(paginator, sort, tableFilter, OrderItemTableEntry);

        this._list.entryUpdated.pipe(takeUntil(this.#unsubscribeAll)).subscribe(this.entryUpdated.bind(this));
        this._list.entryDeleted.pipe(takeUntil(this.#unsubscribeAll)).subscribe(this.entryUpdated.bind(this));
    }

    public get entries(): Array<OrderItemTableEntry>
    {
        return this._list.entries as Array<OrderItemTableEntry>;
    }

    public override dispose(): void
    {
        this.#unsubscribeAll.next(null);
        this.#unsubscribeAll.complete();
    }

    protected queryEntries(): Observable<QueryResult<OrderItemTableEntry>>
    {
        const filter = this.findFilter();
        if (filter.keyword) {
            const keyword = filter.keyword.replace(/^\*|\*$/g, '');
            if (keyword.indexOf('.')) {
                try {
                    filter.keyword = `${new Version(keyword).toLong()}`;
                }
                catch (error) {
                }
            }
        }
        this.applyFilter(filter);

        return fromPromise(this._list.init(filter)) as Observable<QueryResult<OrderItemTableEntry>>;
    }

    protected onLoaded(rows: OrderItemTableEntry[]): void
    {
        super.onLoaded(rows);

        const removeColumn = (column: keyof Material): void =>
        {
            const index = this.displayedColumns.findIndex(_column => _column === column);
            if (index > -1) {
                this.displayedColumns.splice(index, 1);
            }
        };

        removeColumn('marketingPrice');
    }
}
