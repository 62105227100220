import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SturmDrawerComponent } from '@sturm/components/drawer/drawer.component';


@NgModule({
    declarations: [
        SturmDrawerComponent,
    ],
    imports     : [
        CommonModule,
    ],
    exports     : [
        SturmDrawerComponent,
    ],
})
export class SturmDrawerModule
{
}
