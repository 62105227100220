import {Person} from '@yukawa/chain-base-angular-domain';
import {ErrorStatus} from './domain';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.customer.Attribute <br/>
*/
export interface Attribute {
name?: string;
value?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.customer.Customer <br/>
*/
export interface Customer extends  Person{
acceptsMarketing?: boolean;
addressId?: string;
attributes?: Attribute[];
bcDeCustomerId?: string;
bcItCustomerId?: string;
bcUsCustomerId?: string;
currencyCode?: string;
customerCreationDate?: string;
customerId?: string;
customerNumber?: string;
customerProducts?: CustomerProduct[];
customerStatus?: CustomerStatus;
customerType?: CustomerType;
discount?: number;
errorStatus?: ErrorStatus;
externalCustomerId?: string;
gln?: string[];

/**
*	@deprecated
*/
glnCustomerNumber?: string;
lastExportDate?: Date;
marketingAllowance?: number;
netsuiteId?: string;
priceListId?: string;
senderSystem?: string;
shopCustomerId?: string;
syncDate?: Date;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.customer.CustomerProduct <br/>
*/
export interface CustomerProduct {
productNumber?: string;
}

export enum CustomerStatus {
READ_FROM_BIG_COMMERCE = "READ_FROM_BIG_COMMERCE",
TO_BE_DELETED = "TO_BE_DELETED",
DELETED = "DELETED"
}

export enum CustomerType {
B2C = "B2C",
B2B = "B2B"
}

