import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Version } from '@sturm/lib/config';
import { EmojiEvent, EmojiService, MOMENT_FORMATS, NativeElementFormControl } from '@sturm/lib/shared';
import { PlainObject } from 'simplytyped';
import { IQueryTableEntryDetail } from '../../types';


export interface FormFieldConfig
{
    detail: Required<IQueryTableEntryDetail>;
    control: FormControl;
}

@Component({
    selector   : 'lib-query-table-entry-form',
    templateUrl: './form.component.html',
    styleUrls  : ['./form.component.scss'],
    providers  : [
        {
            provide: DateAdapter, useClass: MomentDateAdapter,
            deps   : [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {
            provide: MAT_DATE_FORMATS, useValue: MOMENT_FORMATS,
        },
    ],
})
export class QueryTableEntryFormComponent implements OnChanges
{
    @Input()
    entryDetails!: Array<IQueryTableEntryDetail>;

    @Output() inputChanged = new EventEmitter<boolean>();
    @Output() selectTags   = new EventEmitter<void>();

    formFieldConfig = new Array<FormFieldConfig>();

    detailsForm!: FormGroup;

    private _emojiTextInput!: HTMLInputElement | HTMLTextAreaElement;

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _emojiService: EmojiService,
    )
    {
    }

    get isValid(): boolean
    {
        return !this.detailsForm.invalid;
    }

    init(): void
    {
        this.formFieldConfig.length = 0;

        const formControls: PlainObject = {};
        for (const _detail of this.entryDetails.values()) {
            if (!_detail.canEdit) {
                continue;
            }

            const validators: Array<ValidatorFn> | undefined = [];
            if (_detail.required) {
                validators.push(Validators.required);
            }
            if (_detail.validators) {
                validators.push(..._detail.validators);
            }

            let formState = _detail.value;
            if (formState && _detail.emojiSupport) {
                formState = this._emojiService.colonsToNative(formState);
            }
            const formControl = new FormControl(formState, validators);
            this.formFieldConfig.push({
                detail : _detail as Required<IQueryTableEntryDetail>,
                control: formControl,
            });

            formControls[_detail.key] = formControl;
        }

        this.detailsForm = new FormGroup(formControls);
        this.detailsForm.valueChanges.subscribe(() =>
        {
            this.inputChanged.emit(!this.detailsForm.invalid);
        });

        this._changeDetector.detectChanges();
        this.inputChanged.emit(!this.detailsForm.invalid);
    }

    afterViewInit(): void
    {
        for (const _detail of this.entryDetails) {
            if (_detail.emojiSupport) {
                const abstractControl = this.detailsForm.controls[_detail.key] as NativeElementFormControl;
                if (abstractControl.nativeElement) {
                    this._emojiService.transformColonToNative(
                        abstractControl,
                        abstractControl.nativeElement as any,
                    ).subscribe();
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (changes['entryDetails']) {
            this.init();
            this._changeDetector.detectChanges();
            this.afterViewInit();
        }
    }

    save<T>(): T
    {
        const fileInfo = {} as PlainObject;
        for (const _config of this.formFieldConfig) {
            if (!_config.detail.canEdit) {
                continue;
            }
            else if (!_config.detail.required && _config.control?.value == null || _config.control?.value === '') {
                continue;
            }

            let value = _config.control?.value;

            if (typeof _config.control?.value === 'string') {
                if (_config.detail.emojiSupport) {
                    value = this._emojiService.nativeEmojiToColons(_config.control.value);
                }
                value = value.trim();
            }

            if (_config.detail.options?.some(option => option.objectValue && !value.some((_value: any) => _value === option.objectValue))) {
                value = Array.isArray(value)
                    ? value.map(option => _config.detail.options?.find(_option => _option.value === option)?.objectValue)
                    : _config.detail.options?.find(_option => _option.value === value)?.objectValue;
            }

            if (_config.detail.group == null) {
                fileInfo[_config.detail.key] = value;
            }
            else {
                if (!fileInfo[_config.detail.group]) {
                    fileInfo[_config.detail.group] = {};
                }
                fileInfo[_config.detail.group][_config.detail.key.split('.').pop() as string] = value;
            }
            _config.detail.value = value;
        }

        return fileInfo as T;
    }

    public addEmoji($event: EmojiEvent): void
    {
        this._emojiService.insert($event, this._emojiTextInput);
    }

    public selectEmoji(textInput: HTMLInputElement | HTMLTextAreaElement): void
    {
        this._emojiTextInput = textInput;
    }

    public onVersionBlur(event: FocusEvent, ctrl: FormControl): void
    {
        let value = String(ctrl.value);
        if (ctrl && value && !ctrl.invalid && !ctrl.pristine) {
            value = value.trim();
            if (value !== '') {
                const version = Version.parse(value);
                if (version.major === 0 && version.minor === 0 && version.revision === 0) {
                    version.revision = 1;
                }
                ctrl.setValue(version + '', { emitEvent: false });
            }
        }
        else if (ctrl && value != null && value.trim() === '') {
            ctrl.setValue(null, { emitEvent: false });
        }
    }

    public handleError(error: Error): boolean
    {
        if (!(error instanceof HttpErrorResponse)) {
            return false;
        }

        for (const _config of this.formFieldConfig) {
            if (!_config.detail.entityName) {
                continue;
            }
            if (error.error?.text?.indexOf(`${_config.detail.entityName}.${_config.detail.key}`) > -1) {
                _config.control.setErrors({
                    'error': error.error?.text,
                }, {
                    emitEvent: true,
                });

                return true;
            }
        }

        return false;
    }

    public getErrorMessage(errors: ValidationErrors | null): string
    {
        return errors ? Object.values(errors).join('\n ') : '';
    }
}
