import { EntryDetailType, IQueryTableEntryDetail, ISelectOption, QueryTableEntry } from '@sturm/lib/components';
import { OrderStatus } from '@sturm/lib/domain';
import { Change } from '@yukawa/chain-base-angular-domain';
import { PlainObject, StringKeys } from 'simplytyped';
import { Order } from './order.model';


export class OrderTableEntry extends QueryTableEntry<Order>
{
    static viewConfig: Order = {
        orderId  : '',
        items    : [],
        notes    : '',
        orderDate: '',
        state    : OrderStatus.CANCELLED,
        created  : {
            date : new Date(),
            notes: '',
            user : '',
        },
        change   : {
            date : new Date(),
            notes: '',
            user : '',
        },
    };

    public readonly id: string;
    public readonly name: string;

    public constructor(
        order: Order = OrderTableEntry.viewConfig,
    )
    {
        super(order);

        this.id   = order.orderId ? String(order.orderId) : '';
        this.name = order.externalOrderId || '';

        if (order.created) {
            order.created.date = new Date(order.created.date);
        }
        if (order.change) {
            order.change.date = new Date(order.change.date);
        }
    }

    public get viewConfig(): Order
    {
        return OrderTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'ORDER.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = Order>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value         = item?.[key];
        let options       = new Array<ISelectOption>();
        detail.entityName = 'Order';

        switch ((key as StringKeys<Order>)) {
            case 'change':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'created':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'items':
                return;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
