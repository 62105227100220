import { EntryDetailType, IQueryTableEntryDetail, ISelectOption, QueryTableEntry } from '@sturm/lib/components';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { isEqual, uniqueId } from 'lodash-es';
import { PlainObject, StringKeys } from 'simplytyped';
import { CustomerService } from '../customer';
import { MaterialPrice } from '../material';
import { OrderItem } from './order-item.model';


export class OrderItemTableEntry extends QueryTableEntry<OrderItem>
{
    static viewConfig: OrderItem = {
        id                       : '',
        name                     : '',
        bcSku                    : '',
        bigCommerceOrderProductId: 0,
        buyersItemNumber         : '',
        components               : [],
        conditions               : [],
        ean                      : '',
        gtin                     : '',
        materialId               : '',
        netsuiteMaterialId       : '',
        options                  : [],
        price                    : 0,
        discount                 : 0,
        quantity                 : 0,
        total                    : 0,
        voucherNumber            : '',
        cartDate                 : new Date(),
    };

    public readonly id: string;
    public readonly name: string;

    private readonly _price: MaterialPrice;

    public constructor(
        orderItem: OrderItem = OrderItemTableEntry.viewConfig,
    )
    {
        super(orderItem);

        this.id = String(orderItem.materialId);

        if (orderItem.materialId) {
            this.id = String(orderItem.materialId);
        }
        else {
            this.id      = uniqueId(orderItem.materialId);
            orderItem.id = this.id;
        }

        this.name = orderItem.name;

        orderItem.ean      = null as never;
        orderItem.discount = 0;
        orderItem.price    = 0;
        orderItem.total    = 0;

        this._price          = new MaterialPrice(
            orderItem.product?.prices[0] || {},
            AppInjector.get(CustomerService).customer?.discount,
        );
        this._price.quantity = orderItem.quantity;
    }

    public get price(): MaterialPrice
    {
        return this._price;
    }

    public get quantity(): number
    {
        return this.entity.quantity;
    }

    public set quantity(value: number)
    {
        this.entity.quantity = value;
        this._price.quantity = value;
    }

    /* eslint-disable @typescript-eslint/member-ordering */

    public get viewConfig(): OrderItem
    {
        return OrderItemTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'OrderItem.';
    }

    /* eslint-enable @typescript-eslint/member-ordering */

    public override init(): void
    {
        super.init();
    }

    public equals(other: OrderItem): boolean
    {
        return isEqual(this.entity, other);
    }

    protected override mapDetails<TKey = OrderItem>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value         = item?.[key];
        let options       = new Array<ISelectOption>();
        detail.entityName = 'OrderItem';
        detail.sortable   = false;

        switch ((key as StringKeys<OrderItem>)) {
            case 'bcSku':
            case 'bigCommerceOrderProductId':
            case 'buyersItemNumber':
            case 'cartDate':
            case 'components':
            case 'conditions':
            case 'gtin':
            case 'id':
                return;
            case 'discount':
                type            = 'text';
                detail.required = true;
                Object.defineProperty(detail, 'value', {
                    get: (): string =>
                    {
                        let discount: number;
                        if (this._price) {
                            if (this._price.wholesaleValue && this._price.retailValue) {
                                discount = 100 - (this._price.wholesaleValue / this._price.retailValue) * 100;
                            }
                            else {
                                discount = this._price.discount;
                            }

                            return `${discount}%`;
                        }
                        else {
                            return '';
                        }
                    },
                    set: (v: any): void =>
                    {
                    },
                });
                break;
            case 'ean':
                type = 'text';
                Object.defineProperty(detail, 'value', {
                    get: (): string | undefined => this.entity.product?.ean,
                    set: (): void =>
                    {
                    },
                });
                break;
            case 'materialId':
                type            = 'text';
                detail.required = true;
                break;
            case 'name':
                type            = 'text';
                detail.required = true;
                break;
            case 'netsuiteMaterialId':
            case 'options':
                return;
            case 'price':
                type            = 'text';
                detail.required = true;
                Object.defineProperty(detail, 'value', {
                    get: (): string => this._price?.value ? this._price.toString() : '',
                    set: (v: any): void =>
                    {
                    },
                });
                break;
            case 'quantity':
                type            = 'component';
                detail.required = true;
                break;
            case 'total':
                type            = 'text';
                detail.required = true;
                Object.defineProperty(detail, 'value', {
                    get: (): string => this._price?.valueTotal ? this._price.toString() : '',
                    set: (v: any): void =>
                    {
                    },
                });
                break;
            case 'voucherNumber':
            case 'product':
                return;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
