import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SturmFullscreenComponent } from '@sturm/components/fullscreen/fullscreen.component';


@NgModule({
    declarations: [
        SturmFullscreenComponent,
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        CommonModule,
    ],
    exports     : [
        SturmFullscreenComponent,
    ],
})
export class SturmFullscreenModule
{
}
