// noinspection PointlessArithmeticExpressionJS

import { ArgumentException, isNumber } from '@awesome-nodes/object';
import { PlainObject } from 'simplytyped';


interface IVersion
{
    major: number;
    minor: number;
    revision: number;
}

export class Version implements IVersion
{
    major!: number;
    minor!: number;
    revision!: number;

    constructor(data?: Partial<IVersion> | string | number)
    {
        if (typeof data === 'string') {
            data = Version.parse(data);
        }
        else if (typeof data === 'number') {
            const parts = this.unmaskVersion(data).split('.');

            this.major    = parseInt(parts[0], 10);
            this.minor    = parseInt(parts[1], 10);
            this.revision = parseInt(parts[2], 10);
            return;
        }

        for (const _key in data) {
            if (!isNumber((data as PlainObject)[_key])) {
                throw new ArgumentException('Version must be type of number and range between 0-255.', 'data.' + _key);
            }
        }

        this.major    = data?.major ?? 0;
        this.minor    = data?.minor ?? 0;
        this.revision = data?.revision ?? 0;
    }

    public static parse(text: string): Version
    {
        const data  = { major: 0, minor: 0, revision: 0 };
        const split = text.split(/\./);
        if (split.length > 0) {
            data.major = parseInt(split[0], 10);
        }
        if (split.length > 1) {
            data.minor = parseInt(split[1], 10);
        }
        if (split.length > 2) {
            data.revision = parseInt(split[2], 10);
        }
        return new Version(data);
    }

    // noinspection JSUnusedGlobalSymbols
    public toLong(): number
    {
        return this.maskVersion(`${this.major}.${this.minor}.${this.revision}`);
    }

    public toString(): string
    {
        return [this.major, this.minor, this.revision].join('.');
    }

    /**
     * @param version should be a string in the format of 'vX.Y.Z' where X, Y, Z are
     * For example, 'v1.2.3' represents version 1.2.3.
     * @param maxBits is the number of bits used to represent the number each version number: [vMAJOR.MINOR.PATCH].
     * 8 bits are enough to represent the number [v0.0.0] to [v255.255.255].
     * @returns An unique integer representing the version.
     */
    protected maskVersion(version: string, maxBits = 8): number
    {
        const versions = version.replace('v', '')
            .split('.')
            .map(e => Number(e));

        const major = versions[0];
        const minor = versions[1];
        const patch = versions[2];
        /* eslint-disable no-bitwise */
        return major << maxBits * 2 | minor << maxBits * 1 | patch << maxBits * 0;
        /* eslint-enable no-bitwise */
    }

    /**
     * @param version should be the integer returned by [maskVersion].
     * @param maxBits is the number of bits used to represent the number each version number: [vMAJOR.MINOR.PATCH].
     * 8 bits are enough to represent the number [v0.0.0] to [v255.255.255].
     * @return the original string representing the version.
     */
    protected unmaskVersion(version: number, maxBits = 8): string
    {
        /* eslint-disable no-bitwise */
        const major = (version >> maxBits * 2) & ((1 << maxBits) - 1);
        const minor = (version >> maxBits * 1) & ((1 << maxBits) - 1);
        const patch = (version >> maxBits * 0) & ((1 << maxBits) - 1);
        /* eslint-enable no-bitwise */

        return `${major}.${minor}.${patch}`;
    }
}
