import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Order as IOrder, OrderFilter } from '@sturm/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable } from 'rxjs';
import { Order } from './order.model';


@Injectable({ providedIn: 'root' })
export class OrderService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('orderUrl'));
    }

    loadByKey(orderId: number): Observable<Order>
    {
        return this.http.get<Order>(`${this.formatServiceUrl()}?key=${orderId}`);
    }

    delete(order: Order): Observable<Order>
    {
        return this.http.delete<Order>(`${this.formatServiceUrl()}?key=` + order.orderId);
    }

    create(order: Order): Observable<Order>
    {
        return this.http.post<Order>(this.formatServiceUrl('/create'), order);
    }

    merge(order: IOrder): Observable<Order>
    {
        return this.http.post<Order>(this.formatServiceUrl('/merge'), order);
    }

    put(order: Order): Observable<Order>
    {
        return this.http.post<Order>(this.formatServiceUrl('/put'), order);
    }

    queryOrder(filter: OrderFilter): Observable<QueryResult<Order>>
    {
        return super.query<Order>(this.formatServiceUrl('/query'), filter);
    }

    update(order: Order): Observable<Order>
    {
        return this.http.post<Order>(this.formatServiceUrl('/update'), order);
    }

    send(order: Order): Observable<Order>
    {
        return this.http.post<Order>(this.formatServiceUrl('/create'), order);
    }
}
