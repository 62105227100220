<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">

    <mat-drawer-container class="flex-auto h-full bg-transparent"
                          (backdropClick)="onBackdropClicked()">

        <!-- Drawer -->
        <mat-drawer
            class="w-full sm:w-100"
            [mode]="drawerMode"
            [opened]="false"
            [position]="'end'"
            [disableClose]="true"
            #matDrawer>
            <router-outlet></router-outlet>
        </mat-drawer>

        <mat-drawer-content class="flex flex-col bg-transparent">

            <!-- Main -->
            <div class="flex-auto p-6 pt-8 sm:p-20 sm:pt-8">

                <!-- Product list -->
                <div class="sw-table-wrap mt-8">

                    <div class="subTitle text-center text-secondary"
                         *ngIf="orderItemsTable?.dataSource?.entries.length === 0" transloco="CART.EMPTY"></div>

                    <lib-query-table
                        [actions]="materialActions"
                        [cellComponents]="cellComponents"
                        [dataStore]="dataStore"
                        [filter]="materialFilter"
                        [reloadEntry]="reloadEntry"
                        [rowSelect]="false"
                        (entryAction)="entryAction($event)"
                    ></lib-query-table>
                </div>

                <div class="grid grid-cols-2 lg:grid-cols-3 justify-between gap-8">

                    <mat-form-field
                        class="sturm-mat-dense sturm-mat-no-subscript flex-auto max-w-100 mt-10">
                        <mat-label class="normal-case" transloco="CART.INTERNAL_CODE"></mat-label>
                        <input matInput [autocomplete]="'off'" #internalCode maxlength="100"
                               [(ngModel)]="order.externalOrderId" focused #isCustomerOrderIdFocused="hasFocus"
                               (ngModelChange)="orderChanged()">

                        <mat-hint matSuffix *ngIf="isCustomerOrderIdFocused.hasFocus">
                            {{internalCode.value?.length || 0}}/{{internalCode.maxLength}}</mat-hint>
                        <button mat-icon-button matSuffix class="-mr-4"
                                (click)="order.externalOrderId = undefined; this.orderChanged()">
                            <mat-icon class="icon-size-5">close</mat-icon>
                        </button>
                    </mat-form-field>

                    <lib-min-max-datepicker class="mt-10 min-w-44 sm:min-w-50"
                                            [(date)]="desiredDeliveryDate"
                                            [label]="'CART.DELIVERY_DATE' | transloco"
                                            [hint]="'CART.DELIVERY_DATE_FORMAT' | transloco"
                                            [minDays]="6" [maxDays]="365"
                                            (dateChange)="orderChanged()"
                    >
                        <mat-icon matDatepickerToggleIcon svgIcon="sturm:bookings"
                                  class="icon-size-8 text-primary"></mat-icon>
                    </lib-min-max-datepicker>

                    <div class="w-full lg:flex-grow lg:max-w-180 col-span-2 lg:col-span-1 ml-auto mt-6 space-y-13">
                        <div class="gap-y-4">
                            <div class="grid grid-cols-2 text-xl">
                                <span>{{ 'MATERIAL.SUBTOTAL.PRICE' | transloco }}:</span>
                                <span class="text-right">{{ subtotalPrice }}</span>
                            </div>
                            <div class="grid grid-cols-2 text-xl">
                                <span>{{ 'MATERIAL.SUBTOTAL.DISCOUNT' | transloco }}:</span>
                                <span class="text-right">{{ totalDiscountPrice }}</span>
                            </div>
                            <div class="grid grid-cols-2 text-xl">
                                <span>{{ 'MATERIAL.SUBTOTAL.MARKETING' | transloco }}:</span>
                                <span class="text-right">{{ totalPrice }}</span>
                            </div>
                        </div>
                        <div>
                            <span transloco="CART.VAT_INFO" class="inline-block mb-1"></span>
                            <button mat-flat-button class="sturm-mat-button-large w-full" color="primary"
                                    [disabled]="orderItemsTable?.dataSource?.entries.length === 0"
                                    transloco="BUTTON.SUBMIT" (click)="submitOrder()"></button>
                        </div>
                    </div>
                </div>

            </div>
        </mat-drawer-content>

    </mat-drawer-container>

</div>



