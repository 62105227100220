import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
    SturmNavigationItem,
    SturmNavigationService,
    SturmVerticalNavigationComponent,
} from '@sturm/components/navigation';
import { SturmMediaWatcherService } from '@sturm/services/media-watcher';
import { STURM_VERSION } from '@sturm/version';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { filter, Subject, takeUntil } from 'rxjs';


@Component({
    selector     : 'b2b-layout',
    templateUrl  : './b2b.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class B2bLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    mobileNavigation: Array<SturmNavigationItem>;
    pageName: string;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _sturmMediaWatcherService: SturmMediaWatcherService,
        private _sturmNavigationService: SturmNavigationService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    get version(): string
    {
        return STURM_VERSION;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) =>
            {
                this.navigation       = navigation;
                this.mobileNavigation = [navigation.default.find(item => item.id === 'cart')];
            });

        // Subscribe to media changes
        this._sturmMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) =>
            {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        const setPageName = (): string =>
            this.pageName = this.navigation.default?.find(item => this._router.url.split('?')?.[0] === item.link)?.title;

        // Display current navigation title
        this._router.events.pipe(
            takeUntil(this._unsubscribeAll),
            filter(value => value instanceof NavigationEnd),
        )
            .subscribe((state) =>
            {
                setPageName();
            });
        setPageName();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._sturmNavigationService.getComponent<SturmVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
