import { IsActiveMatchOptions } from '@angular/router';


export interface SturmNavigationItem
{
    id?: string;
    title?: string;
    subtitle?: string;
    type:
        | 'aside'
        | 'basic'
        | 'collapsable'
        | 'divider'
        | 'group'
        | 'spacer';
    hidden?: (item: SturmNavigationItem) => boolean;
    active?: boolean;
    disabled?: boolean;
    tooltip?: string;
    link?: string;
    externalLink?: boolean;
    target?:
        | '_blank'
        | '_self'
        | '_parent'
        | '_top'
        | string;
    exactMatch?: boolean;
    isActiveMatchOptions?: IsActiveMatchOptions;
    function?: (item: SturmNavigationItem) => void;
    classes?: {
        title?: string;
        subtitle?: string;
        icon?: string;
        wrapper?: string;
        nav?: {
            [key: string]: {
                title?: string;
                subtitle?: string;
                icon?: string;
                wrapper?: string;
            };
        };
    };
    icon?: string;
    badge?: {
        title?: string;
        classes?: string;
    };
    children?: SturmNavigationItem[];
    meta?: any;
}

export type SturmVerticalNavigationAppearance =
    | 'default'
    | 'compact'
    | 'dense'
    | 'thin';

export type SturmVerticalNavigationMode =
    | 'over'
    | 'side';

export type SturmVerticalNavigationPosition =
    | 'left'
    | 'right';
