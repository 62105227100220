import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { Observable, tap } from 'rxjs';
import { Customer } from './customer.model';


@Injectable({
    providedIn: 'root',
})
export class CustomerService extends RestAspect
{
    customer?: Customer;

    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('b2bCustomerUrl'));
    }

    loadCustomer(customerId: string): Observable<Customer>
    {
        return this.load<Customer>(this.formatServiceUrl('/load'))
            .pipe(
                tap(customer => this.customer = customer),
            );
    }
}
