import { ModuleWithProviders, NgModule } from '@angular/core';
import { STURM_APP_CONFIG } from '@sturm/services/config/config.constants';
import { SturmConfigService } from '@sturm/services/config/config.service';
import { AppConfig } from 'app/core/config/app.config';


@NgModule()
export class SturmConfigModule
{
    /**
     * Constructor
     */
    constructor(private _sturmConfigService: SturmConfigService)
    {
    }

    /**
     * forRoot method for setting user configuration
     *
     * @param config
     */
    static forRoot(config: AppConfig): ModuleWithProviders<SturmConfigModule>
    {
        return {
            ngModule : SturmConfigModule,
            providers: [
                {
                    provide : STURM_APP_CONFIG,
                    useValue: config,
                },
            ],
        };
    }
}
