import { Injectable } from '@angular/core';
import { QueryTableDatasource, QueryTableDatasourceException } from '@sturm/lib/components';
import { MaterialFilter } from '@sturm/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { lastValueFrom, map } from 'rxjs';
import { MaterialTableEntry } from './material-table-entry.model';
import { Material } from './material.model';
import { MaterialService } from './material.service';


@Injectable({
    providedIn: 'root',
})
export class MaterialDatasource extends QueryTableDatasource<Material, MaterialFilter>
{
    constructor()
    {
        super();
    }

    public async read(filter?: MaterialFilter): Promise<Array<Material>>
    {
        this.query = await lastValueFrom(AppInjector.get(MaterialService).queryMaterial({
            ...filter,
        }).pipe(
            map((result: QueryResult<Material>) =>
            {
                if (filter?.priceListId) {
                    result.items.forEach((item) =>
                    {
                        item.prices = item.prices.filter(prize => prize.priceListId === filter?.priceListId);
                    });
                }
                return result;
            }),
        ));

        return this.query.items;
    }

    public newEntry(item?: Material): MaterialTableEntry
    {
        const entry = new MaterialTableEntry(item);
        entry.init();
        return entry;
    }

    public async create(entry: MaterialTableEntry): Promise<MaterialTableEntry>
    {
        entry = this.newEntry(await lastValueFrom(AppInjector.get(MaterialService).create(entry.entity)));
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: MaterialTableEntry, material?: Partial<Material>): Promise<MaterialTableEntry>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Material file ${entry.id} does not exist.`);
        }

        if (material) {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(MaterialService).update({
                ...material,
                materialId: entry.entity.materialId,
            } as Material)) as Material);
        }

        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: MaterialTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Material file ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(MaterialService).delete(entry.entity));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(materialId: number): Promise<MaterialTableEntry>
    {
        return this.newEntry((await lastValueFrom(AppInjector.get(MaterialService).loadByKey(materialId))));
    }
}
