import { Injectable } from '@angular/core';
import { MaterialFilter } from '@sturm/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { MaterialDatasource } from './material-datasource.model';
import { Material } from './material.model';


@Injectable({
    providedIn: 'root',
})
export class MaterialCartDatasource extends MaterialDatasource
{
    constructor()
    {
        super();
    }

    public override async read(filter?: MaterialFilter): Promise<Array<Material>>
    {
        this.query = {
            ...AppInjector.get(MaterialDatasource).queryResult as any,
            hasMore: false,
            rows   : this.entries.length,
            items  : this.entries,
        };
        return this.query.items;
    }
}
