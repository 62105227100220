import { Component, OnInit } from '@angular/core';
import { MaterialPrice, MaterialTableEntry } from '@sturm/lib/admin';
import { IQueryTableEntryDetail, IQueryTableEntryDetailComponent } from '@sturm/lib/components';


@Component({
    selector   : 'app-material-total',
    templateUrl: './total.component.html',
    styleUrls  : ['./total.component.scss'],
})
export class MaterialTotalComponent implements OnInit, IQueryTableEntryDetailComponent
{
    public detail: IQueryTableEntryDetail;
    public entry: MaterialTableEntry;

    constructor()
    {
    }

    get total(): string
    {
        return MaterialPrice.sumTotal('total', this.entry.price);
    }

    ngOnInit(): void
    {
    }

}
