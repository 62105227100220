import { Component, OnInit } from '@angular/core';
import { OrderItemService, OrderItemTableEntry } from '@sturm/lib/admin';
import { IQueryTableEntryDetail, IQueryTableEntryDetailComponent } from '@sturm/lib/components';


@Component({
    selector   : 'app-material-quantity',
    templateUrl: './quantity.component.html',
    styleUrls  : ['./quantity.component.scss'],
})
export class MaterialQuantityComponent implements OnInit, IQueryTableEntryDetailComponent
{
    public detail: IQueryTableEntryDetail;
    public entry: OrderItemTableEntry;
    public quantity: number;

    constructor(
        private readonly _orderItemService: OrderItemService,
    )
    {
    }

    ngOnInit(): void
    {
        this.quantity = this.entry.price.quantity;
    }

    public quantityChanged($event: number): void
    {
        this.entry.price.quantity  = $event;
        this.entry.entity.quantity = $event;
        this._orderItemService.update(this.entry.entity);
    }

    public validateQuantityInput(event: Event): void
    {
        const pattern = /^[0-9]*$/;
        const target  = event.target as HTMLInputElement;

        if (!pattern.test(target.value)) {
            const quantity = target.value.replace(/[^0-9]/g, '');

            target.value              = quantity;
            this.entry.price.quantity = Number(quantity);
        }
    }
}
