import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@NgModule()
export class IconsModule
{
    /**
     * Constructor
     */
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry,
    )
    {
        // Register icon sets
        this.addSvgIconSet('assets/icons/material-twotone.svg');
        this.addSvgIconSetInNamespace('mat_outline', 'assets/icons/material-outline.svg');
        this.addSvgIconSetInNamespace('mat_solid', 'assets/icons/material-solid.svg');
        this.addSvgIconSetInNamespace('iconsmind', 'assets/icons/iconsmind.svg');
        this.addSvgIconSetInNamespace('feather', 'assets/icons/feather.svg');
        this.addSvgIconSetInNamespace('heroicons_outline', 'assets/icons/heroicons-outline.svg');
        this.addSvgIconSetInNamespace('heroicons_solid', 'assets/icons/heroicons-solid.svg');
        this.addSvgIconSetInNamespace('sturm', 'assets/icons/sturm.svg');

        this.addSvgIconInNamespace('sturm', 'logo', 'assets/icons/logo.svg');
        this.addSvgIconInNamespace('sturm', 'icon', 'assets/icons/icon.svg');
    }

    private addSvgIconSet(url: string): void
    {
        this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl(url));
    }

    private addSvgIconSetInNamespace(namespace: string, url: string): void
    {
        this._matIconRegistry.addSvgIconSetInNamespace(
            namespace,
            this._domSanitizer.bypassSecurityTrustResourceUrl(url),
        );
    }

    private addSvgIconInNamespace(namespace: string, icon: string, url: string): void
    {
        this._matIconRegistry.addSvgIconInNamespace(
            namespace,
            icon,
            this._domSanitizer.bypassSecurityTrustResourceUrl(url),
        );
    }
}
