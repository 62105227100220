import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SturmLoadingBarModule } from '@sturm/components/loading-bar';
import { SharedModule } from '@sturm/shared';
import { EmptyLayoutComponent } from 'app/layout/layouts/empty/empty.component';


@NgModule({
    declarations: [
        EmptyLayoutComponent,
    ],
    imports     : [
        RouterModule,
        SturmLoadingBarModule,
        SharedModule,
    ],
    exports     : [
        EmptyLayoutComponent,
    ],
})
export class EmptyLayoutModule
{
}
