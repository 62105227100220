import { NgModule } from '@angular/core';
import { SturmScrollbarDirective } from '@sturm/directives/scrollbar/scrollbar.directive';


@NgModule({
    declarations: [
        SturmScrollbarDirective,
    ],
    exports     : [
        SturmScrollbarDirective,
    ],
})
export class SturmScrollbarModule
{
}
