import { NgModule } from '@angular/core';
import { SturmUtilsService } from '@sturm/services/utils/utils.service';


@NgModule({
    providers: [
        SturmUtilsService,
    ],
})
export class SturmUtilsModule
{
    /**
     * Constructor
     */
    constructor(private _sturmUtilsService: SturmUtilsService)
    {
    }
}
