import { Injectable } from '@angular/core';
import { QueryTableDatasource, QueryTableDatasourceException } from '@sturm/lib/components';
import { EntityFilter, QueryResult } from '@yukawa/chain-base-angular-domain';
import { OrderItemTableEntry } from './order-item-table-entry.model';
import { OrderItem } from './order-item.model';
import { OrderItemService } from './order-item.service';


@Injectable({
    providedIn: 'root',
})
export class OrderItemDatasource extends QueryTableDatasource<OrderItem, EntityFilter>
{
    constructor(
        private readonly _orderItemService: OrderItemService,
    )
    {
        super();
    }

    public async read(filter?: EntityFilter): Promise<Array<OrderItem>>
    {
        this._orderItemService.load();
        this.query = new QueryResult({
            hasMore: false,
            rows   : this._orderItemService.items.length,
            items  : this._orderItemService.items,
        });
        return this.query.items;
    }

    public newEntry(item?: OrderItem): OrderItemTableEntry
    {
        const entry = new OrderItemTableEntry(item);
        entry.init();
        return entry;
    }

    public async create(entry: OrderItemTableEntry): Promise<OrderItemTableEntry>
    {
        this._orderItemService.add(entry.entity);
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: OrderItemTableEntry, orderItem?: Partial<OrderItem>): Promise<OrderItemTableEntry>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`OrderItem file ${entry.id} does not exist.`);
        }

        this._orderItemService.update(entry.entity);
        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: OrderItemTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`OrderItem file ${entry.id} does not exist.`);
        }

        this._orderItemService.delete(entry.entity);

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(orderItemId: string): Promise<OrderItemTableEntry>
    {
        return this.newEntry(this._orderItemService.loadByKey(orderItemId) as OrderItem);
    }
}
