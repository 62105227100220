import { NgModule } from '@angular/core';
import { SturmMediaWatcherService } from '@sturm/services/media-watcher/media-watcher.service';


@NgModule({
    providers: [
        SturmMediaWatcherService,
    ],
})
export class SturmMediaWatcherModule
{
    /**
     * Constructor
     */
    constructor(private _sturmMediaWatcherService: SturmMediaWatcherService)
    {
    }
}
