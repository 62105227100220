import { EntryDetailType, IQueryTableEntryDetail, ISelectOption, QueryTableEntry } from '@sturm/lib/components';
import { AppInjector, removeEmpty } from '@yukawa/chain-base-angular-client';
import { Nullable, PlainObject, StringKeys } from 'simplytyped';
import { CustomerService } from '../customer';
import { MaterialPrice } from './material-price.model';
import { Material } from './material.model';


export class MaterialTableEntry extends QueryTableEntry<Material>
{
    static viewConfig: Material = {
        name          : '',
        ean           : '',
        materialId    : '',
        mfProductId   : '',
        productGroup  : '',
        size          : '',
        prices        : [],
        price         : 0,
        discount      : 0,
        marketingPrice: 0,
        quantity      : 0,
        total         : 0,
        dimensions    :
            {
                height    : 0,
                length    : 0,
                weight    : 0,
                weightUnit: '',
                width     : 0,
            },
        dutyRateNumber: '',
        lastExportDate: new Date(),
        productType   : 0,
        stock         : 0,
        syncDate      : new Date(),
        taxation      : 0,
        change        : {
            date : new Date(),
            notes: '',
            user : '',
        },
        created       : {
            date : new Date(),
            notes: '',
            user : '',
        },
    };

    public readonly id: string;
    public readonly name: string;

    private readonly _price?: MaterialPrice;

    public constructor(
        material: Material = MaterialTableEntry.viewConfig,
    )
    {
        super(material);

        this.id   = material.materialId ? String(material.materialId) : '';
        this.name = material.name;

        if (material.created) {
            material.created.date = new Date(material.created.date);
        }
        if (material.change) {
            material.change.date = new Date(material.change.date);
        }

        material.size           = '';
        material.price          = 0;
        material.discount       = 0;
        material.marketingPrice = 0;
        material.quantity       = 0;
        material.total          = 0;

        const price = this.entity.prices[0];
        if (price) {
            this._price = new MaterialPrice(
                price,
                AppInjector.get(CustomerService).customer?.discount,
            );
        }
    }

    public get price(): Nullable<MaterialPrice>
    {
        return this._price;
    }

    public get viewConfig(): Material
    {
        return MaterialTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'MATERIAL.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = Material>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value         = item?.[key];
        let options       = new Array<ISelectOption>();
        detail.entityName = 'Material';

        switch ((key as StringKeys<Material>)) {
            case 'active':
            case 'errorStatus':
            case 'netsuiteId':
            case 'prices':
                return;
            case 'ean':
                type            = 'text';
                detail.required = true;
                break;
            case 'materialId':
                type            = 'text';
                detail.required = true;
                break;
            case 'name':
                type            = 'text';
                detail.required = true;
                break;
            case 'size':
                type               = 'text';
                detail.required    = false;
                detail.showInTable = false;
                const dimensions   = (item as Material).dimensions;
                value              = removeEmpty([
                    dimensions.length,
                    dimensions.width,
                    dimensions.height,
                ]).join(' x ');
                if (dimensions.weight) {
                    value += ` ${dimensions.weight}`;

                    if (dimensions.weightUnit) {
                        value += dimensions.weightUnit;
                    }
                }
                break;
            case 'price':
                type            = 'text';
                detail.required = true;
                detail.sortable = false;
                Object.defineProperty(detail, 'value', {
                    get: (): string =>
                    {
                        if (this._price?.retailValue) {
                            return this._price.toString('retailValue');
                        }
                        else if (this._price?.value) {
                            return this._price.toString('value');
                        }
                        else {
                            return '';
                        }
                    },
                    set: (v: any): void =>
                    {
                    },
                });
                break;
            case 'discount':
                type            = 'text';
                detail.required = true;
                detail.sortable = false;
                Object.defineProperty(detail, 'value', {
                    get: (): string =>
                    {
                        let discount: number;
                        if (this._price) {
                            if (this._price.wholesaleValue && this._price.retailValue) {
                                discount = 100 - (this._price.wholesaleValue / this._price.retailValue) * 100;
                            }
                            else {
                                discount = this._price.discount;
                            }

                            return `${discount}%`;
                        }
                        else {
                            return '';
                        }
                    },
                    set: (v: any): void =>
                    {
                    },
                });
                break;
            case 'marketingPrice':
                type            = 'text';
                detail.required = true;
                detail.sortable = false;
                Object.defineProperty(detail, 'value', {
                    get: (): string =>
                    {
                        if (this._price?.wholesaleValue) {
                            return this._price.toString('wholesaleValue');
                        }
                        else if (this._price?.value) {
                            return this._price.toString('total');
                        }
                        else {
                            return '';
                        }
                    },
                    set: (v: any): void =>
                    {
                    },
                });
                break;
            case 'quantity':
                type            = 'text';
                detail.required = true;
                break;
            case 'total':
                type            = 'text';
                detail.required = true;
                break;
            case 'change':
                /*this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });*/
                return;
            case 'created':
                /*this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });*/
                return;
            case 'dimensions':
            case 'dutyRateNumber':
            case 'lastExportDate':
            case 'mfProductId':
            case 'productGroup':
            case 'productType':
            case 'stock':
            case 'syncDate':
            case 'taxation':
                return;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
